import { Address, TClientId, TFacilityId } from '/src/lib/models';
import { FloatString, ReportingPeriodType } from '/src/lib/types';

export type TDashboardDataId = number;

export type TPercentRevenuePerEquipmentCategoryData = {
  category: string;
  percentage: string | number;
};

export type TPercentRevenuePerProgramData = {
  program: string;
  percentage: string | number;
};

export type TQuarterProgramData = {
  name: string;
  price_per_credit: FloatString;
};

export type TReportingPeriodData = {
  reporting_period: string;
  incentive_revenue: string;
  programs: TQuarterProgramData[];
  num_facilities: number;
  equipment: TEquipmentData[];
};

export type TEquipmentData = {
  category: string;
  amount: number;
};

export type TFacilityData = {
  id: TFacilityId;
  name: string;
  address: Address;
  equipment: TEquipmentData[];
};

export interface DashboardData {
  client_id?: TClientId;
  client_name?: string;
  kwh_consumed: number;
  mt_cO2e_displaced: number;
  total_net_revenue: FloatString;
  average_dollar_per_kwh: FloatString;
  total_recs_required: FloatString;
  num_enrolled_equipment: number;
  days_to_next_credit_minting: number;
  percent_revenue_per_equipment_category: TPercentRevenuePerEquipmentCategoryData[];
  percent_revenue_per_program: TPercentRevenuePerProgramData[];
  reporting_periods: TReportingPeriodData[];
  reporting_period_type_id: ReportingPeriodType;
  facilities: TFacilityData[];
}
