import { Box, ResponsiveContext } from 'grommet';
import { Calendar, Globe, LineChart, StarOutline, Trigger } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { config } from 'src/config';
import { ApiError } from '/src/api';
import {
  CurrentFacilityLocationsChart,
  DashboardHeader,
  DashboardInfoCard,
  EVStation,
  EnrollmentTrendsChart,
  EquipmentByFacilityChart,
  LoadingSpinner,
  RevenueByChart,
  RevenueByEquipmentChart,
  RevenueByProgramChart,
  RevenueByReportingPeriodChart,
} from '/src/components';
import { useClientStore, useDashboardStore, useGlobalStore, useUserStore } from '/src/context';
import { DesktopLayout } from '/src/layouts';
import { toastMessages } from '/src/lib/toast';
import { formatNumber, getIsMobile, getPageTitle } from '/src/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Dashboard = observer(() => {
  /* Context */
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const clientStore = useClientStore();
  const dashboardStore = useDashboardStore();
  const screenSize = useContext(ResponsiveContext);

  /* State */
  const [isLoading, setIsLoading] = useState(false);

  /* Computed */
  const isMobile = getIsMobile(screenSize);
  const isAdminDashboard = userStore.isFuseAdmin && !clientStore.selectedClientId;
  const clientId = userStore.isExternalUser ? clientStore.activeClientId : clientStore.selectedClientId;

  /* Methods */
  const fetchDashboard = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      if (clientId) {
        await dashboardStore.getClientView(clientId);
      } else {
        await dashboardStore.getAdminView();
      }
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchDashboard.error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportPDF = async () => {
    const input = document.getElementById('dashboard-content');
    const facilityLocationsChart = document.getElementById('current_facility_locations');
    const exportButton = document.getElementById('export-pdf-button');
  
    if (input && facilityLocationsChart && exportButton) {
      facilityLocationsChart.style.display = 'none';
      exportButton.style.display = 'none';
  
      html2canvas(input, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('DashboardSummary.pdf');
  
        facilityLocationsChart.style.display = '';
        exportButton.style.display = '';
      });
    }
  };

  /* Effects */
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Dashboard');
    return () => dashboardStore.clearDashboardData();
  }, []);

  useEffect(() => {
    fetchDashboard();
  }, [clientId]);

  /* Render */
  return (
    <DesktopLayout>
      {isLoading && <LoadingSpinner />}
      <Box flex="grow">
        {!isLoading && dashboardStore.dashboardData && (
          <Box
            flex="grow"
            gap="1rem"
            pad={{
              horizontal: isMobile ? '3.75rem' : '8rem',
              vertical: isMobile ? '2.25rem' : '3rem',
            }}
            animation={{ type: 'fadeIn', duration: 300 }}
            id="dashboard-content"
          >
            {dashboardStore.dashboardData.client_name && (
              <Box direction={isMobile ? 'column' : 'row'}>
                <DashboardHeader
                  clientName={dashboardStore.dashboardData.client_name}
                  clientId={dashboardStore.dashboardData.client_id}
                  onExportPDF={handleExportPDF}
                />
              </Box>
            )}
            <Box gap="1rem">
              <Box gap="1rem" direction={isMobile ? 'column' : 'row'}>
                <Box id="kwh_consumed" title="kwh_consumed" width={isMobile ? undefined : `${100 / 3}%`}>
                  <DashboardInfoCard
                    value={
                      dashboardStore.dashboardData.kwh_consumed
                        ? formatNumber(dashboardStore.dashboardData.kwh_consumed)
                        : 0
                    }
                    title="kWh Consumed"
                    description="Energy Consumption Used For Reporting"
                    icon={<Trigger size="38px" color="light-1" />}
                  />
                </Box>
                <Box id="mt_co2e_displaced" width={isMobile ? undefined : `${100 / 3}%`}>
                  <DashboardInfoCard
                    value={
                      dashboardStore.dashboardData.mt_cO2e_displaced
                        ? formatNumber(dashboardStore.dashboardData.mt_cO2e_displaced)
                        : 0
                    }
                    title="MT CO2e Displaced"
                    description="Equal To Credits Generated To Date"
                    icon={<Globe size="38px" color="light-1" />}
                  />
                </Box>
                <Box id="total_net_revenue_to_date" width={isMobile ? undefined : `${100 / 3}%`}>
                  <DashboardInfoCard
                    value={
                      dashboardStore.dashboardData.total_net_revenue
                        ? formatNumber(dashboardStore.dashboardData.total_net_revenue, true, true)
                        : 0
                    }
                    title="Total Net Revenue To Date"
                    description="Value Of Credits Transacted"
                    icon={<LineChart size="38px" color="light-1" />}
                  />
                </Box>
              </Box>
              <Box gap="1rem" direction={isMobile ? 'column' : 'row'}>
                <Box id="avg_cost_per_kWh" width={isMobile ? undefined : `${100 / 3}%`}>
                  <DashboardInfoCard
                    value={
                      dashboardStore.dashboardData.average_dollar_per_kwh
                        ? formatNumber(dashboardStore.dashboardData.average_dollar_per_kwh, true, true)
                        : 0
                    }
                    title="Avg. $/kWh"
                    subtitle="/ kWh"
                    icon={<StarOutline size="38px" color="light-1" />}
                  />
                </Box>
                <Box id="enrolled_vehicles_and_equipment" width={isMobile ? undefined : `${100 / 3}%`}>
                  <DashboardInfoCard
                    value={
                      dashboardStore.dashboardData.num_enrolled_equipment
                        ? formatNumber(dashboardStore.dashboardData.num_enrolled_equipment)
                        : 0
                    }
                    title="Enrolled Vehicles & Equipment"
                    subtitle="Units"
                    description="Total Number of Assets Producing Credits"
                    icon={<EVStation />}
                  />
                </Box>
                <Box id="days_to_next_credit_minting" width={isMobile ? undefined : `${100 / 3}%`}>
                  <DashboardInfoCard
                    value={
                      dashboardStore.dashboardData.days_to_next_credit_minting
                        ? formatNumber(dashboardStore.dashboardData.days_to_next_credit_minting)
                        : 0
                    }
                    title="Days To Next Credit Minting"
                    icon={<Calendar size="38px" color="light-1" />}
                  />
                </Box>
              </Box>
            </Box>
            <Box gap="1rem" direction={isMobile ? 'column' : 'row'}>
              <Box id="period_incentive_revenue" width={isMobile ? undefined : '50%'}>
                <RevenueByReportingPeriodChart data={dashboardStore.dashboardData} />
              </Box>
              <Box id="enrollment_trends" width={isMobile ? undefined : '50%'}>
                <EnrollmentTrendsChart data={dashboardStore.dashboardData} />
              </Box>
            </Box>
            {isAdminDashboard && (
              <Box gap="1rem" direction={isMobile ? 'column' : 'row'}>
                <Box id="revenue_by_equipment_type" width={isMobile ? undefined : '50%'}>
                  <RevenueByEquipmentChart data={dashboardStore.dashboardData} />
                </Box>
                <Box id="revenue_by_program" width={isMobile ? undefined : '50%'}>
                  <RevenueByProgramChart data={dashboardStore.dashboardData} />
                </Box>
              </Box>
            )}
            {!isAdminDashboard && (
              <Box gap="1rem" direction={isMobile ? 'column' : 'row'}>
                <Box id="revenue_by" width={isMobile ? undefined : '40%'}>
                  <RevenueByChart data={dashboardStore.dashboardData} />
                </Box>
                <Box id="equipment_by_facility" width={isMobile ? undefined : '60%'}>
                  <EquipmentByFacilityChart 
                    data={dashboardStore.dashboardData} 
                    clientId={clientId}
                  />
                </Box>
              </Box>
            )}
            {!isAdminDashboard && (
              <Box id="current_facility_locations" gap="1rem" direction={isMobile ? 'column' : 'row'}>
                <CurrentFacilityLocationsChart data={dashboardStore.dashboardData} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </DesktopLayout>
  );
});

export default Dashboard;
