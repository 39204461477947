import { ApexOptions } from 'apexcharts';
import { CardBody, CardHeader } from 'grommet';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Box, Card, CardProps, LoadingSpinner, Text } from '/src/components';
import { DashboardData } from '/src/lib/models';
import theme from '/src/theme';

const themeColors = theme.global?.colors ?? {};

const ENROLLMENT_TRENDS_CHART_TITLE = 'Cumulative Enrollment Trends';
const cardHeight = '40.875rem';
const colors: string[] = ['#eba14d', themeColors['accent-3'] as string, themeColors['accent-5'] as string, '#4de6eb'];

const SCALE_FACTORS: Record<string, number> = {
  'Electric Forklift': 1.5,
  'eTRU': 8,
  'Fixed Guideway, Light Rail': 6,
  'Heavy-Duty On-Road EV': 3.5,
  'eCHE': 15,
  'Light-Duty On-Road EV': 2.5
};

const DEFAULT_SCALE_FACTOR_RANGE = { min: 5, max: 10, multiplier: 0.8 };

const chartOptions: ApexOptions = {
  chart: {
    type: 'line',
    fontFamily: 'Roboto Condensed, sans-serif',
    foreColor: themeColors['dark-3'] as string,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: [4],
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    background: {
      borderColor: themeColors.brand as string,
      padding: 9,
      borderRadius: 5,
    },
  },
  colors,
  xaxis: {
    type: 'category',
    title: {
      text: 'Reporting Period',
      style: {
        fontWeight: 400,
        fontSize: '20px',
        color: themeColors['dark-3'] as string,
      },
      offsetY: -10,
    },
    labels: {
      rotate: -90,
      rotateAlways: true,
      offsetX: -1,
      style: {
        fontWeight: 700,
      },
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: (val) => val?.toString().split('.')[0] ?? '',
    },
    style: {
      fontSize: '14px',
    },
  },
  legend: {
    showForSingleSeries: true,
    fontSize: '16px',
  },
};

export const EnrollmentTrendsChart: React.FC<EnrollmentTrendsChartProps> = (props) => {
  /**
   * Props
   */
  const { data, height, ...cardProps } = props;

  /**
   * State
   */
  const [chartData, setChartData] = useState<ApexAxisChartSeries>();
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [categoryStrokes, setCategoryStrokes] = useState<number[]>([]);
  const [chartYAxes, setChartYAxes] = useState<ApexYAxis[]>([]);

  /**
   * Effects
   */
  useEffect(() => {
    const ignore = false;

    if (!ignore) {
      const periods: string[] = [];
      const categories: Record<string, number[]> = {};
      const categoryMaxValues: Record<string, number> = {};

      data.reporting_periods.forEach((period) => {
        periods.push(period.reporting_period);

        if (!categories['Facilities']) categories['Facilities'] = [];
        categories['Facilities'].push(period.num_facilities);
        if (period.num_facilities > (categoryMaxValues['Facilities'] || 0)) {
          categoryMaxValues['Facilities'] = period.num_facilities;
        }

        period.equipment.forEach((equipment) => {
          if (!categories[equipment.category]) categories[equipment.category] = [];
          categories[equipment.category].push(equipment.amount);
          if (equipment.amount > (categoryMaxValues[equipment.category] || 0)) {
            categoryMaxValues[equipment.category] = equipment.amount;
          }
        });
      });

      const series: ApexAxisChartSeries = [];
      const categoryYAxes: ApexYAxis[] = [];
      const strokes: number[] = [];


      series.push({
        name: 'Facilities',
        type: 'line',
        data: categories['Facilities'],
      });

      const equipmentCategories = Object.keys(categories).filter(cat => cat !== 'Facilities');
      const highestEquipmentValue = Math.max(...equipmentCategories.map(cat => categoryMaxValues[cat]));

      const facilitiesYAxis: ApexYAxis = {
        seriesName: 'Facilities',
        show: false,
        max: categoryMaxValues['Facilities'] * 1.15,
      };

      equipmentCategories.forEach((categoryName) => {
        strokes.push(0);
        
        series.push({
          name: categoryName,
          type: 'column',
          data: categories[categoryName],
        });
        
        const scaleFactor = SCALE_FACTORS[categoryName] ?? 
          Math.min(
            DEFAULT_SCALE_FACTOR_RANGE.max, 
            Math.max(
              DEFAULT_SCALE_FACTOR_RANGE.min, 
              (highestEquipmentValue / categoryMaxValues[categoryName]) * DEFAULT_SCALE_FACTOR_RANGE.multiplier
            )
          );
        
        categoryYAxes.push({
          seriesName: categoryName,
          show: false,
          max: categoryMaxValues[categoryName] * scaleFactor,
        });
      });

      setChartData(series);
      setChartLabels(periods);
      setChartYAxes([facilitiesYAxis, ...categoryYAxes]);
      setCategoryStrokes(strokes);
    };
  }, [data]);

  return (
    <Card flex="grow" height={height || cardHeight} {...cardProps}>
      <CardHeader>
        <Text size="xlarge">{ENROLLMENT_TRENDS_CHART_TITLE}</Text>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium', bottom: '2rem' }}>
        {!chartLabels || !categoryStrokes || !chartYAxes || !chartData ? (
          <LoadingSpinner />
        ) : (
          <Box width="100%" flex="grow">
            <Chart
              options={{
                ...chartOptions,
                labels: chartLabels,
                yaxis: chartYAxes,
                stroke: {
                  width: [4, ...categoryStrokes],
                },
                plotOptions: {
                  bar: {
                    columnWidth: '65%',
                  },
                },
              }}
              series={chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type EnrollmentTrendsChartProps = CardProps & {
  data: DashboardData;
};