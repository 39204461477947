import { Form, Button as IconButton, ResponsiveContext, Spinner } from 'grommet';
import { CircleInformation, Copy, Lock } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ApiError,
  ApiErrorItem,
  FacilityService,
  TUpdateUserEmailRequest,
  TUpdateUserRequest,
  UserService,
} from '/src/api';
import {
  Box,
  BreadcrumbNav,
  BreadcrumbNavProps,
  Button,
  FormCard,
  FormCardSection,
  FormPage,
  InfoBox,
  Input,
  Line,
  Select,
  Text,
} from '/src/components';
import { UserInfoBar } from '/src/components/users';
import { config } from '/src/config';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { errorMessages } from '/src/lib/errors';
import {
  ExternalRoles,
  InternalRoles,
  RoleTypes,
  TClientId,
  TFacilityId,
  TUserId,
  User,
  roleIdOptions,
} from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SelectOption, UserDataForm } from '/src/lib/types';
import { PageNotFound } from '/src/pages';
import { getFacilityLabel, getIsMobile, getPageTitle, isValidEmail, parseIdQueryParam, pxToRem } from '/src/utils';

export const UserDetailsPage = observer(() => {
  /* Context */
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const clientStore = useClientStore();
  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);

  /* Query Params */
  const params = useParams();
  const userId = parseIdQueryParam(params.user_id);
  const clientId = parseIdQueryParam(params.client_id);

  /* Refs */
  const defaultFormData = useRef<UserDataForm>({
    name: '',
    email: '',
    phone: '',
    role_id: NaN,
    is_active: false,
    is_email_verified: true,
    facility_ids: [],
    add_facility_ids: [],
    remove_facility_ids: [],
  });

  /* State */
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [isLoadingPasswordResetUrl, setIsLoadingPasswordResetUrl] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [user, setUser] = useState<User>();
  const [facilityOptions, setFacilityOptions] = useState<SelectOption<TFacilityId>[]>();
  const [breadcrumbNavItems, setBreadcrumbNavItems] = useState<BreadcrumbNavProps['previousPages']>([]);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<TClientId>();
  const [passwordResetUrl, setPasswordResetUrl] = useState<string>('');
  const [isInvalidParams, setIsInvalidParams] = useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  // Form Values
  const [formValues, setFormValues] = useState<UserDataForm>(defaultFormData.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  // Password Values
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  /* Computed */
  const isMobile = getIsMobile(screenSize);

  const isOwnUser = userStore.user?.id === userId;
  const ownClient =
    userStore.isExternalUser && clientId === clientStore.activeClientId ? clientStore.activeClient : undefined;
  const isMultiClientUser = (user?.clients.length ?? 0) > 1;

  const currentUserCanView = isOwnUser || userStore.isInternalUser || (userStore.isClientAdmin && !!ownClient);
  const currentUserCanEdit = isOwnUser || userStore.isFuseAdmin || (userStore.isClientAdmin && !!ownClient);

  const filteredRoleIdOptions = useMemo(() => {
    if (!user) return;

    // filter out Sales Rep role for the time being
    const options = roleIdOptions.filter((option) => option.value !== RoleTypes.FuseSalesRep);

    if (user.clients.length > 1 && !isOwnUser) {
      return options.filter((option) => option.value === RoleTypes.ClientAdmin);
    } else if (userStore.isFuseAdmin) {
      if (InternalRoles.includes(user.role.id)) {
        return options.filter((option) => InternalRoles.includes(option.value));
      } else {
        return options.filter((option) => ExternalRoles.includes(option.value));
      }
    } else if (userStore.isExternalUser) {
      return options.filter((option) => ExternalRoles.includes(option.value));
    }
  }, [user, userStore.isFuseAdmin, userStore.isExternalUser, roleIdOptions]);

  /* Methods */
  const updateFormValue = (key: string, value: unknown) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.warn('Couldn\'t copy to clipboard');
    }
  };

  const formatPhoneNumber = (value: string) => {
    const [mainNumber, ...extensionParts] = value.toLowerCase().split(/(ext|x)/i);
    const extension = extensionParts.join('');

    if (mainNumber.match(/[a-zA-Z]/)) {
      const phoneMatch = mainNumber.match(/^\(\d{3}\) \d{3}-\d{4}/);
      return phoneMatch 
        ? phoneMatch[0] + value.slice(phoneMatch[0].length)
        : value;
    }

    const digitsOnly = mainNumber.replace(/[^\d+]/g, '');
    
    if (!digitsOnly) return '';
    if (digitsOnly.startsWith('+')) return `+${digitsOnly.slice(1)}${extension}`;

    let formatted = '';
    if (digitsOnly.length >= 10) {
      formatted = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
      if (digitsOnly.length > 10) formatted += ` ${digitsOnly.slice(10)}`;
      else if (mainNumber.endsWith(' ')) formatted += ' ';
    } else if (digitsOnly.length > 6) {
      formatted = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
    } else if (digitsOnly.length > 3) {
      formatted = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
    } else {
      formatted = `(${digitsOnly}`;
    }

    return formatted + extension;
  };

  const fetchPageData = async (userId: TUserId) => {
    try {
      const user = await UserService.get({ id: userId });

      setUser(user);

      const userValues = {
        ...formValues,
        name: user.name,
        email: user.email,
        phone: user.phone ?? '',
        is_active: user.is_active,
        role_id: user.role.id,
      };

      const client = userStore.isExternalUser ? clientStore.activeClient : user.clients?.[0];

      if (client) userValues.client_id = client.id;
      userValues.facility_ids = user.facilities.map((f) => f.id);

      setFormValues(userValues);

      const navItems = client
        ? [
          { name: 'Clients', link: userStore.isInternalUser ? '/clients' : undefined },
          { name: client.name, link: !userStore.isFacilityUser ? `/clients/${client?.id}` : undefined },
          { name: 'Users', link: !userStore.isFacilityAdmin ? `/clients/${client.id}/users` : undefined },
        ]
        : [{ name: 'Users', link: '/users' }];
      setBreadcrumbNavItems(navItems);

      if (client) await fetchFacilityOptions(client.id);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchUser.error);
    }
  };

  const fetchFacilityOptions = async (clientId: TClientId) => {
    if (isLoadingFacilities) return;

    try {
      setIsLoadingFacilities(true);

      const { data: facilities } = await FacilityService.list({ client_id: clientId });
      setFacilityOptions(
        (facilities ?? []).map((facility) => ({
          label: getFacilityLabel(facility),
          value: facility.id,
        }))
      );
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchUser.error);
    } finally {
      setIsLoadingFacilities(false);
    }
  };

  const updateUser = async () => {
    if (!userId || isUpdating || !user) return;
    const errors: Record<string, string> = {};
    if (!formValues.name) errors['name'] = 'Name is required';
    else if (formValues.name.length > 50) errors['name'] = 'Name cannot be more than 50 characters';
    if (!formValues.email) errors['email'] = 'Email is required';
    else if (!isValidEmail(formValues.email)) errors['email'] = 'Must be a valid email address';
    else if (formValues.email.length > 100) errors['email'] = 'Email cannot be more than 100 characters';
    if (formValues.phone && formValues.phone.length > 50)
      errors['phone'] = 'Phone number cannot be more than 50 characters';
    if (!formValues['role_id']) errors['role_id'] = 'Role is required';
    if (ExternalRoles.includes(formValues.role_id) && !formValues.client_id) errors['client_id'] = 'Client is required';
    if (formValues.role_id === RoleTypes.FacilityAdmin && !formValues.facility_ids.length)
      errors['facility_ids'] = 'Must select at least one Facility';
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    const updatePromises: Promise<any>[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any

    const updatedUser: TUpdateUserRequest = {
      name: formValues.name,
      phone: formValues.phone || null,
    };

    if ((userStore.isFuseAdmin || (userStore.isClientAdmin && !!ownClient)) && !isOwnUser) {
      updatedUser.role_id = formValues.role_id;
    }

    if (userStore.isFuseAdmin) {
      updatedUser.is_active = formValues.is_active;
      updatedUser.is_email_verified = formValues.is_email_verified;
    }

    // Facility Users
    if (formValues.role_id === RoleTypes.FacilityAdmin) {
      const newFacilityIds = formValues.facility_ids.filter(
        (facility_id) => !user.facilities.find(({ id }) => id === facility_id)
      );
      if (newFacilityIds.length) updatedUser.add_facility_ids = newFacilityIds;

      const oldFacilityIds = user.facilities
        .filter(({ id }) => !formValues.facility_ids.includes(id))
        .map((facility) => facility.id);
      if (oldFacilityIds.length) updatedUser.remove_facility_ids = oldFacilityIds;
    }

    updatePromises.push(UserService.update(userId, updatedUser));

    // Update Email
    if (formValues.email !== user.email) {
      const updatedEmail: TUpdateUserEmailRequest = {
        email: formValues.email,
      };
      updatePromises.push(UserService.updateEmail(userId, updatedEmail));
    }

    try {
      setIsUpdating(true);
      await Promise.all(updatePromises);
      setFormErrors({});
      toast.success(toastMessages.updateUser.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateUser.error);
    } finally {
      setIsUpdating(false);
    }
  };

  const getPasswordResetUrl = async (userId: TUserId) => {
    if (isLoadingPasswordResetUrl) return;

    try {
      setIsLoadingPasswordResetUrl(true);

      const { password_reset_url } = await UserService.generatePasswordResetUrl(userId);

      setPasswordResetUrl(password_reset_url);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateUser.error);
    } finally {
      setIsLoadingPasswordResetUrl(false);
    }
  };

  const updatePassword = async () => {
    if (!userId) return;
    const errors: Record<string, string> = {};
    if (!oldPassword) errors['old_password'] = errorMessages.notCurrentPassword;
    if (newPassword && !confirmPassword) errors['confirm_password'] = errorMessages.passwordNotConfirmed;
    else if (!newPassword && confirmPassword) errors['new_password'] = errorMessages.passwordNotEntered;
    if (newPassword && newPassword.length < 10) errors['new_password'] = errorMessages.passwordTooShort;
    else if (newPassword !== confirmPassword) errors['confirm_password'] = errorMessages.passwordsNotMatch;
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsUpdatingPassword(true);
      await UserService.updatePassword(userId, { old_password: oldPassword, new_password: newPassword });
      toast.success(toastMessages.updatePassword.success);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setFormErrors({ ...formErrors, old_password: '', new_password: '', confirm_password: '' });
    } catch (error) {
      const err = error as ApiError;
      switch (err.status) {
        case 401:
          formErrors['old_password'] = errorMessages.invalidCurrentPassword;
          break;
        case 422: {
          (err.body.errors ?? []).forEach((e: ApiErrorItem) => {
            if (e.field === 'password' && e.rule === 'minLength') {
              setFormErrors({
                ...formErrors,
                new_password: e.args.minLength
                  ? `Password must be at least ${e.args.minLength} characters`
                  : errorMessages.passwordTooShortGeneric,
              });
            } else {
              globalStore.handleApiError(err, toastMessages.updatePassword.error);
            }
          });
          break;
        }
        default:
          globalStore.handleApiError(err, toastMessages.updatePassword.error);
          break; 
      }
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  /* Effects */
  useEffect(() => {
    if (formValues.name) {
      document.title = getPageTitle(config.client.basePageTitle, formValues.name);
    }
  }, [formValues.name]);

  useEffect(() => {
    if (userStore.user && userId) {
      if (!currentUserCanView) {
        navigate(clientStore.activeClientId ? `/clients/${clientStore.activeClientId}/users` : '/');
      } else {
        setIsLoading(true);
        fetchPageData(userId).finally(() => setIsLoading(false));
      }
    }
  }, [userStore.user, userId, currentUserCanView, clientStore.activeClientId]);

  useEffect(() => {
    if (!!filteredRoleIdOptions?.length && !formValues.role_id) {
      setFormValues({
        ...formValues,
        role_id: filteredRoleIdOptions[0].value,
      });
    }
  }, [filteredRoleIdOptions, formValues]);

  useEffect(() => {
    if (!user?.email) return;

    if (formValues.email !== user.email && !isChangingEmail) {
      setIsChangingEmail(true);
    } else if (formValues.email === user.email && isChangingEmail) {
      setIsChangingEmail(false);
    }
  }, [formValues.email, user?.email, isChangingEmail]);

  useEffect(() => {
    if (isMultiClientUser && selectedClientId) {
      fetchFacilityOptions(selectedClientId);
    }
  }, [isMultiClientUser, selectedClientId]);

  //404 Redirect
  useEffect(() => {
    if (!isLoading) {
      const invalid = Number.isNaN(userId) || (clientId && Number.isNaN(clientId) || user?.id === undefined);
      setIsInvalidParams(invalid);
    }
  }, [userId, user, clientId, isLoading]); 

  /* Render */
  return isInvalidParams ? (
    <PageNotFound />
  ) : (
    <FormPage
      title={user?.name ?? ''}
      breadcrumbNav={<BreadcrumbNav previousPages={breadcrumbNavItems} currentPageName={user?.name ?? ''} />}
      userInfoBar={
        user && (
          <UserInfoBar
            user={user}
            isActive={!!formValues.is_active}
            setIsActive={(value) => updateFormValue('is_active', value)}
            userCanEdit={!!currentUserCanEdit}
          />
        )
      }
      isLoading={isLoading}
      isUserPage
    >
      <Box fill="horizontal">
        <Form>
          <Box direction={isOwnUser && !isMobile ? 'row' : 'column'} gap="1rem">
            <FormCard
              title="User Information"
              icon={<CircleInformation size="24px" color="brand" />}
              isLoading={isLoading || isUpdating || isUpdatingPassword}
              onSubmit={updateUser}
              fill={isOwnUser && !isMobile ? undefined : 'horizontal'}
              width={isOwnUser && !isMobile ? '60%' : undefined}
              hideSaveButton={!currentUserCanEdit}
            >
              <Box direction={!isOwnUser && !isMobile ? 'row' : 'column'} gap="1rem">
                <FormCardSection title="Contact Information" width={!isOwnUser && !isMobile ? '50%' : '100%'}>
                  <Input
                    name="user_name"
                    label="Name"
                    value={formValues.name}
                    setValue={(value) => updateFormValue('name', value)}
                    error={formErrors['name']}
                    onSubmit={updateUser}
                    disabled={!currentUserCanEdit}
                    required
                  />
                  <Input
                    name="user_email"
                    label="Email"
                    type="email"
                    value={formValues.email}
                    setValue={(value) => updateFormValue('email', value)}
                    error={formErrors['email']}
                    onSubmit={updateUser}
                    disabled={!currentUserCanEdit}
                    required
                  />
                  {isChangingEmail && (
                    <InfoBox>
                      <CircleInformation size="16px" />
                      <Text fontFamily="Lato, sans-serif">
                        {`A verification email will be sent to this email address. Until the new address is verified, ${
                          isOwnUser ? 'you' : 'this User'
                        } must continue using ${isOwnUser ? 'your' : 'their'} current email address to log in.`}
                      </Text>
                    </InfoBox>
                  )}
                  <Input
                    name="user_phone"
                    label="Phone"
                    value={formValues.phone}
                    setValue={(value) => updateFormValue('phone', formatPhoneNumber(value))}
                    error={formErrors['phone']}
                    onSubmit={updateUser}
                    disabled={!currentUserCanEdit}
                    placeholder={isPhoneFocused ? '(555) 555-5555' : ''}
                    onFocus={() => setIsPhoneFocused(true)}
                    onBlur={() => setIsPhoneFocused(false)}
                  />
                </FormCardSection>
                {!isOwnUser && !isMobile ? <Line direction="vertical" margin="3rem" /> : <Line margin="0.5rem" />}
                {/* <Box direction={!isOwnUser && !isMobile ? 'row' : 'column'} gap="1rem"> */}
                <Box width={!isOwnUser && !isMobile ? '50%' : '100%'} gap="1rem">
                  <FormCardSection
                    title="User Role"
                    // width={!isOwnUser && !isMobile ? '50%' : undefined}
                  >
                    {isMultiClientUser && (
                      <InfoBox>
                        <CircleInformation size="16px"></CircleInformation>
                        <Text fontFamily="Lato, sans-serif">
                          User Role applies to all Companies {isOwnUser ? 'you are' : 'this User is'} assigned to.
                        </Text>
                      </InfoBox>
                    )}
                    <Select
                      name="role_id"
                      label="User Role"
                      value={formValues.role_id}
                      setValue={(value) => updateFormValue('role_id', value)}
                      error={formErrors['role_id']}
                      options={filteredRoleIdOptions ?? []}
                      required
                      disabled={!currentUserCanEdit || isOwnUser}
                    />
                    {ExternalRoles.includes(formValues.role_id) && user && (
                      <>
                        {!isMultiClientUser ? (
                          <Input
                            name="client_name"
                            label="Company"
                            value={user.clients.find((c) => c.id === formValues.client_id)?.name ?? ''}
                            required
                            disabled
                          />
                        ) : (
                          <Select
                            name="client_name"
                            label="Company"
                            placeholder={`${user.clients.length} assigned`}
                            value={selectedClientId}
                            setValue={setSelectedClientId}
                            options={user.clients.map((client) => ({ label: client.name, value: client.id }))}
                            error={formErrors['client_name']}
                          />
                        )}
                      </>
                    )}
                    {formValues.role_id === RoleTypes.FacilityAdmin &&
                      !isOwnUser &&
                      !!facilityOptions?.length &&
                      (isMultiClientUser ? !!selectedClientId : true) && (
                      <Select
                        name="facility_ids"
                        multiple
                        label="Facilities"
                        values={formValues.facility_ids}
                        setValues={(value) => updateFormValue('facility_ids', value)}
                        options={facilityOptions}
                        error={formErrors['facility_ids']}
                        required
                        disabled={isLoadingFacilities}
                      />
                    )}
                  </FormCardSection>
                  <Line margin="0.5rem" />
                  {user && userStore.isFuseAdmin && !isOwnUser && (
                    <InfoBox>
                      <Box gap="1rem" flex="grow">
                        <Text color="dark-1" size="large" weight={300}>
                          Password Reset URL
                        </Text>
                        <Box direction={!isOwnUser && !isMobile ? 'row' : 'column'} gap="1rem">
                          <Button
                            label="Generate"
                            height={pxToRem(45)}
                            toUpperCase={false}
                            width={!isOwnUser && !isMobile ? '20%' : '100%'}
                            onClick={() => getPasswordResetUrl(user.id)}
                            disabled={isLoadingPasswordResetUrl}
                          />
                          <Box
                            background="white"
                            direction="row"
                            flex="grow"
                            pad={{
                              horizontal: '0.75rem',
                              vertical: isMobile ? '0.5rem' : undefined,
                            }}
                            width={!isOwnUser && !isMobile ? '80%' : '100%'}
                            gap="xsmall"
                            border={{ color: 'light-5', size: '0.8px' }}
                            borderRadius="6px"
                            align="center"
                            justify="between"
                          >
                            {!isLoadingPasswordResetUrl && (
                              <Box animation={{ type: 'fadeIn', duration: 300 }}>
                                <Text truncate={true}>{passwordResetUrl}</Text>
                              </Box>
                            )}
                            {isLoadingPasswordResetUrl && (
                              <Box align="center" fill="horizontal">
                                <Spinner />
                              </Box>
                            )}
                            {!!passwordResetUrl && (
                              <Box animation={{ type: 'fadeIn', duration: 300 }} align="center">
                                <IconButton
                                  icon={<Copy size="16px" color="light-3" />}
                                  tip="Copy to clipboard"
                                  onClick={() => copyToClipboard(passwordResetUrl)}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </InfoBox>
                  )}
                </Box>
              </Box>
            </FormCard>
            {isOwnUser && (
              <FormCard
                title="Update Password"
                icon={<Lock size="24px" color="brand" />}
                isLoading={isLoading}
                onSubmit={updatePassword}
                fill="horizontal"
              >
                <InfoBox>
                  <CircleInformation size="16px" />
                  <Text fontFamily="Lato, sans-serif">Passwords must be at least 10 characters.</Text>
                </InfoBox>
                <Input
                  name="old_password"
                  label="Current Password"
                  value={oldPassword}
                  setValue={setOldPassword}
                  error={formErrors['old_password']}
                  onSubmit={updatePassword}
                  componentType="password"
                  hideOptionalText
                />
                <Line margin="0.5rem" />
                <Input
                  name="new_password"
                  label="New Password"
                  value={newPassword}
                  setValue={setNewPassword}
                  error={formErrors['new_password']}
                  onSubmit={updatePassword}
                  componentType="password"
                  hideOptionalText
                />
                <Input
                  name="confirm_password"
                  label="Confirm New Password"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  error={formErrors['confirm_password']}
                  onSubmit={updatePassword}
                  componentType="password"
                  hideOptionalText
                />
              </FormCard>
            )}
          </Box>
        </Form>
      </Box>
    </FormPage>
  );
});
