import { ApexOptions } from 'apexcharts';
import { Box, CardBody, CardHeader, Text } from 'grommet';
import { ColorType } from 'grommet/utils';
import { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardProps, LoadingSpinner } from '/src/components';
import { DashboardData } from '/src/lib/models';
import theme from '/src/theme';
import { standardizeFacilityAddress } from '/src/utils';
import { FacilityService } from '/src/api/services';
import { useNavigate } from 'react-router-dom';

export type TFacilitiesData = Record<string, Record<string, number>>;

export const EquipmentByFacilityChart: React.FC<EquipmentByFacilityChartProps> = (props) => {
  /**
   * Props
   */
  const { data, clientId, height, ...boxProps } = props;

  const [facilitiesData, setFacilitiesData] = useState<TFacilitiesData>({});
  const [seriesData, setSeriesData] = useState<ApexAxisChartSeries>([]);
  const [facilityIdMap, setFacilityIdMap] = useState<Record<string, number>>({});
  const [orderedFacilityIds, setOrderedFacilityIds] = useState<number[]>([]);

  const chartLabels = Object.keys(facilitiesData);

  const navigate = useNavigate();

  /* Refs */
  const themeColors = useRef(theme.global?.colors ?? {});
  const chartTitle = useRef('Equipment Type By Facility');
  const cardHeight = useRef('31.5rem');
  const chartColors = useRef<ColorType[]>([
    themeColors.current['accent-3'],
    themeColors.current['accent-5'],
    themeColors.current['brand'],
  ]);

  const chartOptions = useRef<ApexOptions>({
    chart: {
      type: 'bar',
      stacked: true,
      fontFamily: 'Roboto Condensed, sans-serif',
      foreColor: themeColors.current['dark-3'] as string,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
    },
    colors: chartColors.current,
    xaxis: {
      position: 'top',
      labels: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontWeight: 700,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '14px',
      },
    },
    legend: {
      showForSingleSeries: true,
      showForZeroSeries: false,
      fontSize: '16px',
      position: 'bottom',
      markers: {
        radius: 10,
      },
    },
  });

  /* Effects */
  useEffect(() => {
    const fetchFacilityIds = async () => {
      if (!data?.facilities || !clientId) return;
      
      const { data: facilities } = await FacilityService.list({ client_id: clientId });
      
      const facilityMapped = facilities.reduce((acc, facility) => {
        const addressKey = `${facility.address_line1}_${facility.address_city}`.toLowerCase();
        return {
          ...acc,
          [addressKey]: facility.id,
        };
      }, {});
      
      setFacilityIdMap(facilityMapped);
    };

    fetchFacilityIds().catch(e => console.error('Error fetching facility IDs:', e));
  }, [data?.facilities, clientId]);

  useEffect(() => {
    const facilities: TFacilitiesData = {};

    if (!data || !data.facilities || !facilityIdMap) {
      return;
    }
  
    const filteredFacilities = data.facilities.filter((facility) => {
      const addressKey = `${facility.address.line1}_${facility.address.city}`.toLowerCase();
      return facilityIdMap[addressKey] !== undefined;
    });

    const dataFacilities = filteredFacilities
      .sort(
        (a, b) =>
          b.equipment.map((e) => e.amount).reduce((total, amount) => total + amount, 0) -
          a.equipment.map((e) => e.amount).reduce((total, amount) => total + amount, 0)
      )
      .slice(0, 10); 

    const newOrderedFacilityIds: number[] = [];

    for (const facility of dataFacilities) {
      const addressKey = `${facility.address.line1}_${facility.address.city}`.toLowerCase();
      const facilityId = facilityIdMap[addressKey];

      if (!facilityId) continue;

      const facilityAddress = `${standardizeFacilityAddress(facility.address.line1)}, ${facility.address.city}, ${
        facility.address.region_short_code
      } ${facility.address.post_code}`;

      facilities[facilityAddress] = {};
      newOrderedFacilityIds.push(facilityId);

      facility.equipment.forEach(equipment => {
        facilities[facilityAddress][equipment.category] = equipment.amount;
      });
    }

    setFacilitiesData(facilities);
    chartOptions.current = {
      ...chartOptions.current,
      chart: {
        ...chartOptions.current.chart,
        events: {
          dataPointSelection: (_, __, config) => {
            navigate(`/clients/${clientId}/facilities/${newOrderedFacilityIds[config.dataPointIndex]}`);
          }
        }
      },
    };
  
    const seriesData: ApexAxisChartSeries = [];
  
    for (const facilityName in facilities) {
      for (const categoryName in facilities[facilityName]) {
        const existingIndex = seriesData.findIndex((s) => s.name === categoryName);
  
        if (existingIndex > -1) {
          const existingData = seriesData[existingIndex].data as number[];
          existingData.push(facilities[facilityName][categoryName]);
          seriesData[existingIndex].data = existingData;
        } else {
          const newData = {
            name: categoryName,
            data: [facilities[facilityName][categoryName]],
          };
          seriesData.push(newData);
        }
      }
    }
  
    setSeriesData(
      seriesData.filter((datum) => !!datum.data.reduce((total, amount) => ((total as number) += amount as number)))
    );
  
    setOrderedFacilityIds(newOrderedFacilityIds);
  }, [data?.facilities, facilityIdMap, clientId, navigate]);

  /* Render */
  return (
    <Card flex="grow" height={height || cardHeight.current} {...boxProps}>
      <CardHeader>
        <Text size="xlarge">{chartTitle.current}</Text>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium', bottom: '2rem' }}>
        {!seriesData || !chartLabels ? (
          <LoadingSpinner />
        ) : (
          <Box width="100%" flex="grow" direction="row">
            <Box 
              width="250px" 
              justify="center" 
              margin={{ top: '20px' }}
            >
              {chartLabels.map((label, index) => (
                <Text
                  key={label}
                  size="small"
                  style={{
                    fontSize: '14px',
                    lineHeight: '1.2',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginBottom: '28px',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/clients/${clientId}/facilities/${orderedFacilityIds[index]}`)}
                >
                  {label}
                </Text>
              ))}
            </Box>
            <Box flex="grow">
              <Chart
                options={{
                  ...chartOptions.current,
                  xaxis: {
                    ...chartOptions.current.xaxis,
                    categories: chartLabels.map(() => ''),
                  },
                }}
                series={seriesData}
                type="bar"
                width="100%"
                height="100%"
              />
            </Box>
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type EquipmentByFacilityChartProps = CardProps & {
  data: DashboardData;
  clientId: number;
};
