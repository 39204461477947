import { ApiError } from '../api';

export type ToastMessage = {
  loading: string;
  success: string;
  info?: string;
  error: (err: ApiError) => string;
};

export type ToastMessages = {
  // Generic Responses
  generic: ToastMessage;

  // Forms
  formValidation: ToastMessage;

  // Statements
  statements: ToastMessage;

  // Search
  search: ToastMessage;

  // Change Logs
  fetchAuditLog: ToastMessage;
  listAuditLogs: ToastMessage;
  resolveAuditLog: ToastMessage;
  resolveAuditLogs: ToastMessage;

  // Auth
  login: ToastMessage;
  logout: ToastMessage;
  updatePassword: ToastMessage;
  resetPassword: ToastMessage;
  requestPasswordReset: ToastMessage;

  // Bank Account Types
  listBankAccountTypes: ToastMessage;

  // Roles
  listRoles: ToastMessage;

  // Clients
  fetchClient: ToastMessage;
  listClients: ToastMessage;
  createClient: ToastMessage;
  updateClient: ToastMessage;
  listClientReportingPeriods: ToastMessage;

  // Commissions
  fetchCommission: ToastMessage;
  listCommissions: ToastMessage;
  createCommission: ToastMessage;
  updateCommission: ToastMessage;

  // Dashboard
  fetchDashboard: ToastMessage;

  // Equipment
  fetchEquipment: ToastMessage;
  listEquipment: ToastMessage;
  listEquipmentCategories: ToastMessage;
  createEquipment: ToastMessage;
  updateEquipment: ToastMessage;
  retireEquipment: ToastMessage;
  deleteEquipment: ToastMessage;
  updateMultipleMeteredEquipment: ToastMessage;

  // Equipment Usages
  fetchEquipmentUsage: ToastMessage;
  listEquipmentUsages: ToastMessage;
  updateEquipmentUsage: ToastMessage;

  // Facilities
  fetchFacility: ToastMessage;
  listFacilities: ToastMessage;
  createFacility: ToastMessage;
  updateFacility: ToastMessage;
  retireFacility: ToastMessage;
  deleteFacility: ToastMessage;
  updateNonMeteredEquipment: ToastMessage;
  duplicateFacility: ToastMessage;

  // FSE Registration Types
  listFSERegistrationStatuses: ToastMessage;

  // Fuel Pathways
  listFuelPathways: ToastMessage;

  // Incentive Statements
  listIncentiveStatements: ToastMessage;
  getIncentiveStatement: ToastMessage;

  // Programs
  listPrograms: ToastMessage;

  // Regions
  listRegions: ToastMessage;

  // Remittance Methods
  listRemittanceMethods: ToastMessage;

  // Users
  fetchUser: ToastMessage;
  listUsers: ToastMessage;
  createUser: ToastMessage;
  updateUser: ToastMessage;
  updateEmail: ToastMessage;
  verifyEmail: ToastMessage;
};

export const toastMessages: ToastMessages = {
  generic: {
    loading: 'Loading...',
    success: 'Success',
    error: () => 'An error has occurred.',
  },

  // Forms
  formValidation: {
    loading: 'Validating...',
    success: 'Success',
    error: (fieldName: unknown) => `${fieldName} is required`,
  },

  // Search
  search: {
    loading: 'Searching...',
    success: 'Search successful.',
    error: () => 'There was an issue completing the search.',
  },

  // Change Logs
  fetchAuditLog: {
    loading: 'Loading change log...',
    success: 'Change log was loaded successfully.',
    error: () => 'Could not load change log.',
  },
  listAuditLogs: {
    loading: 'Loading change logs list...',
    success: 'Change logs list was loaded successfully.',
    error: () => 'Could not load change logs list.',
  },
  resolveAuditLog: {
    loading: 'Resolving change log...',
    success: 'Change log was marked as resolved.',
    error: () => 'An error occurred while resolving log.',
  },
  resolveAuditLogs: {
    loading: 'Resolving change logs...',
    success: 'Change logs were marked as resolved.',
    error: () => 'An error occurred while resolving logs.',
  },

  // Authentication
  login: {
    loading: 'Logging in...',
    success: 'Successfully logged in.',
    error: () => 'There was an error logging in.',
  },
  logout: {
    loading: 'Logging out...',
    success: 'Successfully logged out.',
    error: () => 'There was an error logging in.',
  },
  updatePassword: {
    loading: 'Updating password...',
    success: 'Password was updated successfully.',
    error: () => 'Could not update password.',
  },
  resetPassword: {
    loading: 'Setting new password...',
    success: 'Password was updated successfully.',
    error: () => 'Could not set new password.',
  },
  requestPasswordReset: {
    loading: 'Sending password reset email...',
    success: 'Password reset email sent successfully.',
    error: () => 'Could not send password reset email.',
  },

  // Bank Account Types
  listBankAccountTypes: {
    loading: 'Loading bank account type list...',
    success: 'Bank account types were loaded successfully.',
    error: () => 'Could not load bank account types.',
  },

  // Roles
  listRoles: {
    loading: 'Loading user roles...',
    success: 'Roles were loaded successfully.',
    error: () => 'Could not load user roles.',
  },

  // Clients
  fetchClient: {
    loading: 'Loading Client...',
    success: 'Client was loaded successfully.',
    error: () => 'Could not load Client.',
  },
  listClients: {
    loading: 'Loading Clients list...',
    success: 'Clients list was loaded successfully.',
    error: () => 'Could not load Clients list.',
  },
  createClient: {
    loading: 'Creating Company...',
    success: 'Company was created successfully.',
    error: () => 'Could not create Company.',
  },
  updateClient: {
    loading: 'Updating Client...',
    success: 'Client was updated successfully.',
    error: () => 'Could not update Client.',
  },
  listClientReportingPeriods: {
    loading: 'Loading Client Reporting Periods list...',
    success: 'Client Reporting Periods list was loaded successfully.',
    error: () => 'Could not load Client Reporting Periods list.',
  },

  // Commissions
  fetchCommission: {
    loading: 'Loading Commission...',
    success: 'Commission was loaded successfully.',
    error: () => 'Could not load Commission.',
  },
  listCommissions: {
    loading: 'Loading Commissions list...',
    success: 'Commissions list was loaded successfully.',
    error: () => 'Could not load Commissions list.',
  },
  createCommission: {
    loading: 'Creating Company...',
    success: 'Company was created successfully.',
    error: () => 'Could not create Company.',
  },
  updateCommission: {
    loading: 'Updating Commission...',
    success: 'Commission was updated successfully.',
    error: () => 'Could not update Commission.',
  },

  // Dashboard
  fetchDashboard: {
    loading: 'Loading Dashboard...',
    success: 'Dashboard was loaded successfully.',
    error: () => 'Could not load Dashboard.',
  },

  // Equipment
  fetchEquipment: {
    loading: 'Loading Equipment...',
    success: 'Equipment was loaded successfully.',
    error: () => 'Could not load Equipment.',
  },
  listEquipment: {
    loading: 'Loading Equipment list...',
    success: 'Equipment list was loaded successfully.',
    error: () => 'Could not load Equipment list.',
  },
  listEquipmentCategories: {
    loading: 'Loading Equipment categories list...',
    success: 'Equipment categories were loaded successfully.',
    error: () => 'Could not load Equipment categories.',
  },
  createEquipment: {
    loading: 'Creating Equipment...',
    success: 'Equipment was created successfully.',
    error: () => 'Could not create Equipment.',
  },
  updateEquipment: {
    loading: 'Updating Equipment...',
    success: 'Equipment was updated successfully.',
    error: () => 'Could not update Equipment.',
  },
  retireEquipment: {
    loading: 'Retiring Equipment...',
    success: 'Equipment was retired successfully.',
    error: () => 'Could not retire Equipment.',
  },
  deleteEquipment: {
    loading: 'Deleting Equipment...',
    success: 'Equipment was deleted successfully.',
    error: () => 'Could not delete Equipment.',
  },
  updateMultipleMeteredEquipment: {
    loading: 'Bulk updating metered Equipment...',
    success: 'Metered Equipment was updated successfully.',
    error: () => 'An error occurred while bulk updating metered Equipment.',
  },

  // Equipment Usages
  fetchEquipmentUsage: {
    loading: 'Loading Equipment usage data...',
    success: 'Equipment usage data was loaded successfully.',
    error: () => 'Could not load Equipment usage data.',
  },
  listEquipmentUsages: {
    loading: 'Loading Equipment usages list...',
    success: 'Equipment usages list was loaded successfully.',
    error: () => 'Could not load Equipment usages list.',
  },
  updateEquipmentUsage: {
    loading: 'Updating Equipment usage data...',
    success: 'Equipment usage data was updated successfully.',
    error: () => 'Could not update Equipment usage data.',
  },

  // Facilities
  fetchFacility: {
    loading: 'Loading Facility...',
    success: 'Facility was loaded successfully.',
    error: () => 'Could not load Facility.',
  },
  listFacilities: {
    loading: 'Loading Facilities list...',
    success: 'Facilities list was loaded successfully.',
    error: () => 'Could not load Facilities list.',
  },
  createFacility: {
    loading: 'Creating Facility...',
    success: 'Facility was created successfully.',
    error: () => 'Could not create Facility.',
  },
  updateFacility: {
    loading: 'Updating Facility...',
    success: 'Facility was updated successfully.',
    error: () => 'Could not update Facility.',
  },
  retireFacility: {
    loading: 'Retiring Facility...',
    success: 'Facility was retired successfully.',
    error: () => 'Could not retire Facility.',
  },
  deleteFacility: {
    loading: 'Deleting Facility...',
    success: 'Facility was deleted successfully.',
    error: () => 'Could not delete Facility.',
  },
  updateNonMeteredEquipment: {
    loading: 'Bulk updating non-metered Equipment...',
    success: 'Unmetered Equipment was updated successfully.',
    error: () => 'An error occurred while bulk updating non-metered Equipment.',
  },
  duplicateFacility: {
    loading: '',
    success: '',
    error: (err) => `${err.body?.program_name} already has a Facility with this address.`,
  },

  // FSE Registration Types
  listFSERegistrationStatuses: {
    loading: 'Loading FSE registration types list...',
    success: 'FSE registration types were loaded successfully.',
    error: () => 'Could not load FSE registration types.',
  },

  // Fuel Pathways
  listFuelPathways: {
    loading: 'Loading Fuel pathways list...',
    success: 'Fuel pathways were loaded successfully.',
    error: () => 'Could not load Fuel pathways.',
  },

  // Incentive Statements
  listIncentiveStatements: {
    loading: 'Loading Incentive Statement list...',
    success: 'Incentive Statements were loaded successfully.',
    error: () => 'Could not load Incentive Statements.',
  },
  getIncentiveStatement: {
    loading: 'Loading Incentive Statement...',
    success: 'Incentive Statement was loaded successfully.',
    error: () => 'Could not load Incentive Statement.',
  },

  // Programs
  listPrograms: {
    loading: 'Loading Programs list...',
    success: 'Programs were loaded successfully.',
    error: () => 'Could not load Programs.',
  },

  // Regions
  listRegions: {
    loading: 'Loading Regions list...',
    success: 'Regions were loaded successfully.',
    error: () => 'Could not load Regions.',
  },

  // Remittance Methods
  listRemittanceMethods: {
    loading: 'Loading remittance methods list...',
    success: 'remittance methods were loaded successfully.',
    error: () => 'Could not load remittance methods.',
  },

  // Users
  fetchUser: {
    loading: 'Loading User...',
    success: 'User was loaded successfully.',
    error: () => 'Could not load User.',
  },
  listUsers: {
    loading: 'Loading Users list...',
    success: 'Users list was loaded successfully.',
    error: () => 'Could not load Users list.',
  },
  createUser: {
    loading: 'Creating User...',
    success: 'User was created successfully.',
    error: () => 'Could not create User.',
  },
  updateUser: {
    loading: 'Updating User...',
    success: 'User was updated successfully.',
    error: () => 'Could not update User.',
  },
  updateEmail: {
    loading: 'Updating email address...',
    success: 'Email address was updated successfully.',
    error: () => 'Could not update email address.',
  },
  verifyEmail: {
    loading: 'Verifying email address...',
    success: 'Email address was successfully verified. You may now log in with this email.',
    error: () => 'There was an error verifying this email address.',
  },
};
