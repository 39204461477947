import dayjs from 'dayjs';
import { ResponsiveContext } from 'grommet';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Box, FormCardSection, Input, Line, Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { ClientDataForm } from '/src/lib/types';
import { getFormattedDateString, getIsMobile, getPeriodFromDateString, getQuarterFromDate } from '/src/utils';

export type ClientGeneralInformationProps = {
  formValues: ClientDataForm;
  updateFormValue: <T>(key: string, value: T) => void;
  formErrors: Record<string, string>;
  isCanada: boolean;
  id?: string;
  isUpdatePage?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};

export const ClientGeneralInformation: React.FC<ClientGeneralInformationProps> = observer((props) => {
  /* Props */
  const { formValues, updateFormValue, formErrors, isCanada, id, isUpdatePage, onSubmit } = props;

  /* Context */
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const screenSize = useContext(ResponsiveContext);

  /* Computed */
  const isMobile = getIsMobile(screenSize);
  const isActive = !formValues.end_date || dayjs(formValues.end_date).isAfter(dayjs(new Date()));

  const reportingPeriodOptions = isCanada ? globalStore.reportingYearOptions : globalStore.reportingQuarterOptions;

  const firstPeriodLabel = isCanada
    ? formValues.first_active_reporting_quarter?.slice(0, 4)
    : getQuarterFromDate(dayjs(formValues.first_active_reporting_quarter).toDate());

  /* Render */
  return (
    <FormCardSection id={id} title="General Information">
      <Box direction={isMobile ? 'column' : 'row'} gap="1rem">
        <Box width={isMobile ? '100%' : '50%'}>
          <Box gap="1rem">
            <Input
              name="client_name"
              label="Company Legal Name"
              value={formValues.name}
              setValue={(value) => updateFormValue<string>('name', value)}
              error={formErrors['name']}
              onSubmit={onSubmit}
              required
              disabled={!userStore.isFuseAdmin}
            />
            <Input
              name="website"
              label="Website URL"
              value={formValues.website}
              setValue={(value) => updateFormValue<string>('website', value)}
              error={formErrors['website']}
              onSubmit={onSubmit}
              disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
            />
          </Box>
          <Line />
          <Box flex="grow" height="15rem">
            <Input
              name="comments"
              label="Comments"
              value={formValues.comments ?? ''}
              setValue={(value) => updateFormValue<string>('comments', value)}
              error={formErrors['comments']}
              onSubmit={onSubmit}
              placeholder=""
              centerLabel={false}
              componentType="textArea"
              disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
            />
          </Box>
        </Box>
        {isMobile ? <Line margin="0.5rem" /> : <Line direction="vertical" margin="2rem" />}
        <Box width={isMobile ? '100%' : '50%'}>
          {isUpdatePage && (
            <>
              <Box gap="1rem">
                <Input
                  name="status"
                  label="Client Status"
                  value={isActive ? 'Active' : 'Inactive'}
                  setValue={() => {
                    // blank method
                  }}
                  disabled
                />
              </Box>
              <Line />
            </>
          )}
          <Box gap="1rem">
            <Box direction={isMobile ? 'column' : 'row'} gap="1rem">
              {isUpdatePage ? (
                <Input
                  name="first_active_reporting_quarter"
                  label="First Reporting Period"
                  fill="horizontal"
                  value={firstPeriodLabel}
                  setValue={() => {
                    // blank method
                  }}
                  disabled
                />
              ) : (
                <Select
                  id="first_active_reporting_quarter"
                  name="first_active_reporting_quarter"
                  label="First Reporting Period"
                  placeholder="Select Period..."
                  fill="horizontal"
                  value={formValues.first_active_reporting_quarter}
                  setValue={(value) => updateFormValue('first_active_reporting_quarter', value)}
                  error={formErrors['first_active_reporting_quarter']}
                  options={reportingPeriodOptions}
                  required
                />
              )}
              <Input
                name="start_date"
                label="FuSE Start Date"
                fill="horizontal"
                value={formValues.start_date}
                setValue={(value) => updateFormValue('start_date', value)}
                error={formErrors['start_date']}
                onSubmit={onSubmit}
                componentType="date"
                required={
                  !(isUpdatePage || (!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)))
                }
                disabled={
                  isUpdatePage || (!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser))
                }
                displayValue={getFormattedDateString(formValues.start_date, isCanada)}
              />
              {isUpdatePage && !userStore.isExternalUser && (
                <Input
                  name="end_date"
                  label="FuSE End Date"
                  fill="horizontal"
                  value={formValues.end_date}
                  setValue={(value) => updateFormValue('end_date', value)}
                  error={formErrors['end_date']}
                  onSubmit={onSubmit}
                  componentType="date"
                  disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
                  displayValue={getPeriodFromDateString(formValues.end_date ?? '', isCanada)}
                />
              )}
            </Box>
          </Box>
          <Line />
          <Box gap="1rem">
            <Input
              name="business_id"
              label="FEIN or Business Number"
              value={formValues.business_id}
              setValue={(value) => updateFormValue('business_id', value)}
              error={formErrors['business_id']}
              onSubmit={onSubmit}
              required
              disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
            />
          </Box>
        </Box>
      </Box>
    </FormCardSection>
  );
});
