import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box, FormCardSection, Input, Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { ClientDataForm } from '/src/lib/types';

export const ClientCorporateInformation: React.FC<ClientCorporateInformationProps> = observer((props) => {
  /* Props */
  const { formValues, updateFormValue, formErrors, id, onSubmit } = props;

  /* Context */
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  return (
    <FormCardSection id={id} title="Corporate Address">
      <Box margin={{ bottom: '1rem' }} gap="1rem">
        <Input
          name="hq_address_line1"
          label="Address"
          value={formValues.hq_address_line1}
          setValue={(value) => updateFormValue('hq_address_line1', value)}
          error={formErrors['hq_address_line1']}
          onSubmit={onSubmit}
          required
          disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
        />
        <Input
          name="hq_address_line2"
          label="Address (continued)"
          value={formValues.hq_address_line2 ?? ''}
          setValue={(value) => updateFormValue('hq_address_line2', value)}
          error={formErrors['hq_address_line2']}
          onSubmit={onSubmit}
          disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
        />
        <Box direction="row" gap="1rem">
          <Input
            name="hq_address_city"
            label="City"
            value={formValues.hq_address_city}
            setValue={(value) => updateFormValue('hq_address_city', value)}
            error={formErrors['hq_address_city']}
            onSubmit={onSubmit}
            required
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Select
            name="hq_address_region_id"
            label="State / Province"
            value={formValues.hq_address_region_id}
            setValue={(value) => updateFormValue('hq_address_region_id', value)}
            options={globalStore.regions
              .map((region) => ({
                label: `${region.name} (${region.short_code})`,
                value: region.id,
              }))}
            error={formErrors['hq_address_region_id']}
            required
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Input
            name="hq_address_post_code"
            label="Postal Code"
            value={formValues.hq_address_post_code}
            setValue={(value) => updateFormValue('hq_address_post_code', value)}
            error={formErrors['hq_address_post_code']}
            onSubmit={onSubmit}
            required
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
        </Box>
      </Box>
    </FormCardSection>
  );
});

export type ClientCorporateInformationProps = {
  formValues: ClientDataForm;
  updateFormValue: (key: string, value: string | number) => void;
  formErrors: Record<string, string>;
  id?: string;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};