import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, ClientService, IncentiveStatementService } from '/src/api';
import {
  Box,
  IncentiveStatementDataTable,
  IncentiveStatementDescription,
  IncentiveStatementFuseInfo,
  IncentiveStatementInfoBar,
  Line,
  LoadingSpinner,
  Text,
  Button,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Client, IncentiveStatement, TIncentiveStatementId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { PageNotFound } from '/src/pages';
import { pxToPt } from '/src/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const IncentiveStatementDetails = observer(() => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** Query Params **/
  const params = useParams();
  const incentiveStatementId = parseInt(params.incentive_statement_id ?? '');
  const isInvalidParams = Number.isNaN(incentiveStatementId);

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [incentiveStatement, setIncentiveStatement] = useState<IncentiveStatement>();
  const [client, setClient] = useState<Client>();

  /** Refs **/
  const contentRef = useRef<HTMLDivElement>(null);

  /** Methods **/
  const fetchPageData = async (incentiveStatementId: TIncentiveStatementId) => {
    if (!incentiveStatementId) return;

    try {
      setIsLoading(true);
      const incentiveStatement = await IncentiveStatementService.getIncentiveStatement({ id: incentiveStatementId });
      setIncentiveStatement(incentiveStatement);

      const client =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === incentiveStatement.client_id)
          : await ClientService.get({ id: incentiveStatement.client_id });
      setClient(client);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.getIncentiveStatement.error);
    } finally {
      setIsLoading(false);
    }
  };

  const generatePDF = async () => {
    if (!contentRef.current || !incentiveStatement || !client) return;
  
    const originalElement = contentRef.current;
    const tempElement = originalElement.cloneNode(true) as HTMLElement;
    
    tempElement.style.width = '1200px'; 
    tempElement.style.padding = '20px';
    tempElement.style.backgroundColor = 'white';
    
    const tables = tempElement.querySelectorAll('table');
    tables.forEach((table: HTMLTableElement) => {
      table.style.width = '100%';
      table.style.borderCollapse = 'collapse';
      table.style.marginBottom = '30px';
      
      const cells = table.querySelectorAll('th, td');
      cells.forEach((cell: HTMLTableCellElement) => {
        cell.style.padding = '8px';
        cell.style.fontSize = '14px';
      });
    });
  
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);

    const contentWidth = tempElement.scrollWidth;
    const contentHeight = tempElement.scrollHeight;
      
    const canvas = await html2canvas(tempElement, {
      scale: 1.5, 
      logging: false,
      useCORS: true,
      allowTaint: true,
      width: contentWidth,
      height: contentHeight + 100,
    });
  
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [310, 250],
    });
      
    const pageWidth = pdf.internal.pageSize.getWidth();
    
    const imgWidth = pageWidth - 20; 
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
    pdf.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      10, 
      0, 
      imgWidth,
      imgHeight
    );
      
    const fileName = `incentive-statement-${client.name.replace(/\s+/g, '-').toLowerCase()}-${incentiveStatementId}.pdf`;
    pdf.save(fileName);
  };
  
  /** Effects **/
  useEffect(() => {
    if (incentiveStatementId) {
      fetchPageData(incentiveStatementId);
    }
  }, [incentiveStatementId]);

  /** Render **/
  return isInvalidParams ? (
    <PageNotFound />
  ) : !isLoading && incentiveStatement && client ? (
    <Box>
        <Button
          label={'Download PDF'}
          onClick={generatePDF}
          margin={{ top: pxToPt('16px'), bottom: pxToPt('16px') }}
        />
      <Box fill="horizontal" background="brand" height={pxToPt('8px')} />
      <Box pad={{ horizontal: pxToPt('44px'), vertical: pxToPt('24px') }} fontFamily="Lato, sans-serif">
      <div ref={contentRef}>
        {/** Header **/}
        <Box direction="row" align="center" justify="between" margin={{ bottom: pxToPt('16px') }}>
          <IncentiveStatementFuseInfo />
          <IncentiveStatementDescription />
        </Box>

        {/** Title **/}
        <Box margin={{ bottom: pxToPt('16px') }}>
          <Text size={pxToPt('30px')} weight={700} letterSpacing={pxToPt('-0.4px')}>
            Incentive Statement
          </Text>
        </Box>

        {/** Info Bar **/}
        <IncentiveStatementInfoBar
          client={client}
          incentiveStatement={incentiveStatement}
          margin={{ bottom: pxToPt('4px') }}
          pad={{ horizontal: pxToPt('4px') }}
        />

        {/** Line **/}
        <Line margin={pxToPt('14px')} size="xsmall" color="text" />

        {/** Data Table **/}
        <IncentiveStatementDataTable incentiveStatement={incentiveStatement} margin={{ bottom: pxToPt('24px') }} />
        </div>
      </Box>
    </Box>
  ) : (
    <LoadingSpinner size="large" />
  );
});
