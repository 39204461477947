import { Box, Form, ResponsiveContext, Tip } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ClientService } from '/src/api';
import { BreadcrumbNav, EntityIcon, FormCard, FormCardSection, FormPage, Input, Line, Text } from '/src/components';
import { config } from '/src/config';
import { useEquipmentStore, useEquipmentUsageStore, useGlobalStore, useUserStore } from '/src/context';
import { Equipment, EquipmentUsage, Model, ModelName } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, EquipmentUsageDataForm } from '/src/lib/types';
import { PageNotFound } from '/src/pages';
import {
  formatNumber,
  getFormErrors,
  getIsMobile,
  getModelLabel,
  getPageTitle,
  getPeriodFromDateString,
} from '/src/utils';

export const EquipmentUsageDetailsPage: React.FC<EquipmentUsageDetailsProps> = observer(() => {
  /** Context **/
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const equipmentStore = useEquipmentStore();
  const equipmentUsageStore = useEquipmentUsageStore();
  const screenSize = useContext(ResponsiveContext);
  const params = useParams();

  /** State **/
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [clientName, setClientName] = useState('');
  const [equipmentUsage, setEquipmentUsage] = useState<EquipmentUsage>();
  const [equipment, setEquipment] = useState<Equipment>();

  const [formValues, setFormValues] = useState<EquipmentUsageDataForm>({
    fuel_pathway_id: undefined,
    is_book_and_claim_applied: false,
    battery_capacity_rating_ah: '',
    voltage: '',
    is_paused: false,
    total_kwh: '',
    charge_cycles_per_shift: '',
    shifts_per_day: '',
    work_days_per_quarter: '',
    percent_charger_efficiency_rating: '',
    percent_charge_return_factor: '',
    percent_depth_of_discharge: '',
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const isMobile = getIsMobile(screenSize);
  const clientId = parseInt(params.client_id ?? '');
  const equipmentId = parseInt(params.equipment_id ?? '');
  const equipmentUsageId = parseInt(params.equipment_usage_id ?? '');
  const equipmentLabel = equipment ? getModelLabel(equipment as Model, ModelName.Equipment) : undefined;
  const isInvalidParams = Number.isNaN(clientId) || Number.isNaN(equipmentId) || Number.isNaN(equipmentUsageId);
  const isCanada = equipment?.facility?.address_region?.country_id === CountryId.Canada;
  const isMetered = equipment?.is_metered;

  const reportingQuarter = equipmentUsage
    ? getPeriodFromDateString(equipmentUsage.start_reporting_quarter, isCanada)
    : undefined;

  const canEditQuarter =
    reportingQuarter && globalStore.currentQuarter && reportingQuarter === globalStore.currentQuarter;

  const hasErrors = !!Object.values(formErrors).filter((e) => !!e).length;

  /** Methods **/
  const updateFormValue = (key: string, value: number) => {
    if (key === 'charge_cycles_per_shift' && (value as number) > 9.99) {
      setFormErrors({ ...formErrors, charge_cycles_per_shift: 'Value cannot exceed 9.99' });
    } else if (key === 'shifts_per_day' && (value as number) > 9.99) {
      setFormErrors({ ...formErrors, shifts_per_day: 'Value cannot exceed 9.99' });
    } else if (key === 'work_days_per_quarter' && (value as number) > 92) {
      setFormErrors({ ...formErrors, work_days_per_quarter: 'Value cannot exceed 92 days' });
    }
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) {
      setFormErrors({
        ...formErrors,
        [key]: '',
      });
    }
  };

  const fetchEquipmentUsage = async () => {
    if (!equipmentUsageId) return;
    const equipmentUsage = await equipmentUsageStore.getEquipmentUsage({ id: equipmentUsageId });
    setEquipmentUsage(equipmentUsage);
    setFormValues({
      fuel_pathway_id: equipmentUsage?.fuel_pathway_id,
      is_book_and_claim_applied: equipmentUsage?.is_book_and_claim_applied ?? false,
      battery_capacity_rating_ah: equipmentUsage?.battery_capacity_rating_ah?.toString() ?? '',
      voltage: equipmentUsage?.voltage?.toString() ?? '',
      is_paused: equipmentUsage?.is_paused ?? false,
      total_kwh: equipmentUsage?.total_kwh ?? '',
      charge_cycles_per_shift: equipmentUsage?.charge_cycles_per_shift ?? '',
      shifts_per_day: equipmentUsage?.shifts_per_day ?? '',
      work_days_per_quarter: equipmentUsage?.work_days_per_quarter ?? '',
      percent_charger_efficiency_rating: equipmentUsage?.percent_charger_efficiency_rating?.toString() ?? '',
      percent_charge_return_factor: equipmentUsage?.percent_charge_return_factor?.toString() ?? '',
      percent_depth_of_discharge: equipmentUsage?.percent_depth_of_discharge?.toString() ?? '',
    });
  };

  const fetchPageData = async () => {
    if (isLoading || !clientId || !equipmentId || !equipmentUsageId) return;

    try {
      setIsLoading(true);

      const clientName =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === clientId)?.name
          : (await ClientService.get({ id: clientId }))?.name;
      setClientName(clientName ?? '');

      const equipment = await equipmentStore.fetchEquipment(equipmentId);
      setEquipment(equipment);

      await fetchEquipmentUsage();
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchEquipmentUsage.error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMeteredUsage = async () => {
    if (!equipment || !equipmentUsage || !canEditQuarter) return;
    const errors = getFormErrors(
      {
        total_kwh: { label: 'Total kWh', required: true },
        fuel_pathway_id: { label: 'Fuel Pathway', required: true },
      },
      formValues
    );
    if (errors) {
      setFormErrors(errors);
      return;
    }

    await equipmentUsageStore.updateMeteredEquipmentUsage({
      equipment_usage_id: equipmentUsage.id,
      fuel_pathway_id: formValues.fuel_pathway_id ?? NaN,
      is_book_and_claim_applied: formValues.is_book_and_claim_applied,
      total_kwh: parseFloat(parseFloat(formValues.total_kwh as string).toFixed(2)),
    });
  };

  const updateUnmeteredUsage = async () => {
    if (!equipment || !equipmentUsage || !canEditQuarter) return;
    const errors = getFormErrors(
      {
        charge_cycles_per_shift: { label: 'Charge Cycles / Shift', required: true, max: 9.99, nonZero: true },
        shifts_per_day: { label: 'Shifts / Day', required: true, max: 9.99, nonZero: true },
        work_days_per_quarter: { label: 'Work Days / Quarter', required: true, max: 92, nonZero: true },
        percent_charger_efficiency_rating: {
          label: 'Charger Efficiency Rating',
          required: true,
        },
        percent_charge_return_factor: { label: 'Charge Return Factor', required: true },
        percent_depth_of_discharge: { label: 'Depth of Discharge', required: true },
      },
      formValues
    );
    if (errors) {
      setFormErrors(errors);
      return;
    }

    await equipmentUsageStore.updateUnmeteredEquipmentUsage({
      equipment_usage_id: equipmentUsage.id,
      fuel_pathway_id: equipmentUsage?.fuel_pathway_id,
      is_book_and_claim_applied: equipmentUsage?.is_book_and_claim_applied ?? false,
      battery_capacity_rating_ah: equipmentUsage?.battery_capacity_rating_ah ?? NaN,
      voltage: equipmentUsage?.voltage ?? NaN,
      is_paused: equipmentUsage?.is_paused ?? false,
      charge_cycles_per_shift: parseFloat(parseFloat(formValues.charge_cycles_per_shift as string).toFixed(2)),
      shifts_per_day: parseFloat(parseFloat(formValues.shifts_per_day as string).toFixed(2)),
      work_days_per_quarter: parseFloat(parseFloat(formValues.work_days_per_quarter as string).toFixed(2)),
      percent_charger_efficiency_rating: parseInt(formValues.percent_charger_efficiency_rating as string),
      percent_charge_return_factor: parseInt(formValues.percent_charge_return_factor as string),
      percent_depth_of_discharge: parseInt(formValues.percent_depth_of_discharge as string),
    });
  };

  const updateEquipmentUsage = async () => {
    if (isUpdating || !equipment) return;

    try {
      setIsUpdating(true);

      if (isMetered) {
        await updateMeteredUsage();
      } else {
        await updateUnmeteredUsage();
      }

      toast.success(toastMessages.updateEquipmentUsage.success);

      await fetchEquipmentUsage();
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateEquipmentUsage.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(
      config.client.basePageTitle,
      equipment?.name ?? `${equipment?.manufacturer}: ${equipment?.serial_number}`,
      'Usage'
    );
  }, [equipment]);

  useEffect(() => {
    if (clientId && equipmentId && equipmentUsageId) {
      fetchPageData();
    }
  }, [clientId, equipmentId, equipmentUsageId]);

  /** Render **/
  return isInvalidParams ? (
    <PageNotFound />
  ) : (
    <FormPage
      title={equipmentLabel}
      titleLinkUrl={`/clients/${clientId}/equipment/${equipmentId}`}
      titleLinkIconSize="18px"
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
            { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
            {
              name: 'Equipment',
              link: equipment?.facility_id
                ? `/clients/${clientId}/facilities/${equipment?.facility_id}/equipment`
                : undefined,
            },
            {
              name: equipmentLabel ?? '',
              link: `/clients/${clientId}/equipment/${equipmentId ?? ''}`,
            },
            { name: 'Usages ' },
          ]}
          currentPageName={getPeriodFromDateString(equipmentUsage?.start_reporting_quarter ?? '', isCanada)}
        />
      }
      isLoading={isLoading}
      isDetailPage
    >
      <Form>
        <FormCard
          title={`${reportingQuarter} Usage`}
          icon={<EntityIcon entityName={'EquipmentUsage'} size="24px" color="#EAAD47" />}
          onSubmit={updateEquipmentUsage}
          isLoading={isLoading || isUpdating}
          hideRequiredText={!canEditQuarter}
          hideSaveButton={!canEditQuarter}
          saveButtonDisabled={hasErrors}
        >
          {equipment && equipmentUsage && (
            <Box direction={isMobile ? 'column' : 'row'} margin={{ bottom: '1rem' }}>
              <Box width="60%" gap="1rem">
                <FormCardSection title="Equipment Details">
                  <Box direction="row">
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Category
                      </Text>
                      <Text fontFamily="Lato, sans-serif">{equipment.equipment_type.equipment_category?.name}</Text>
                    </Box>
                    <Box width="50%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Type
                      </Text>
                      <Text fontFamily="Lato, sans-serif">{equipment.equipment_type.name}</Text>
                    </Box>
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Metered
                      </Text>
                      <Text fontFamily="Lato, sans-serif">{isMetered ? 'Yes' : 'No'}</Text>
                    </Box>
                  </Box>
                  <Line margin="0.5rem" />
                  <Box direction="row">
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Manufacturer
                      </Text>
                      <Text fontFamily="Lato, sans-serif">{equipment.manufacturer}</Text>
                    </Box>
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Model Year
                      </Text>
                      <Text fontFamily="Lato, sans-serif">{equipment.model_year}</Text>
                    </Box>
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Serial Number
                      </Text>
                      <Text fontFamily="Lato, sans-serif">
                        <Tip content={equipment.serial_number}>
                          {equipment.serial_number.length >= 21
                            ? `${equipment.serial_number.slice(0, 4)}...${equipment.serial_number.slice(-4)}`
                            : equipment.serial_number}
                        </Tip>
                      </Text>
                    </Box>
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Unit No.
                      </Text>
                      <Text fontFamily="Lato, sans-serif">{equipment.unit_number}</Text>
                    </Box>
                  </Box>
                  <Box direction="row">
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Fuel Pathway Code
                      </Text>
                      <Text fontFamily="Lato, sans-serif">
                        {equipmentUsageStore.fuelPathwayOptions.find(
                          (option) => option.value === equipmentUsage.fuel_pathway_id
                        )?.label || equipmentUsage.fuel_pathway_id}
                      </Text>
                    </Box>
                    <Box width="25%" gap="xsmall">
                      <Text color="accent-1" size="small" toUpperCase>
                        Book & Claim
                      </Text>
                      <Text fontFamily="Lato, sans-serif">
                        {equipmentUsage.is_book_and_claim_applied ? 'Applied' : 'Not Applied'}
                      </Text>
                    </Box>
                    {!isMetered && (
                      <>
                        <Box width="25%" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Battery Capacity (Ah)
                          </Text>
                          <Text fontFamily="Lato, sans-serif">{equipmentUsage.battery_capacity_rating_ah}</Text>
                        </Box>
                        <Box width="25%" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Voltage
                          </Text>
                          <Text fontFamily="Lato, sans-serif">{equipmentUsage.voltage}</Text>
                        </Box>
                      </>
                    )}
                  </Box>
                </FormCardSection>
              </Box>
              <Line direction="vertical" />
              <Box width="40%" gap="1rem">
                {isMetered && (
                  <FormCardSection title="Usage Details">
                    <Box direction="row" gap="1rem">
                      {canEditQuarter ? (
                        <Input
                          name="total_kwh"
                          label="Total kWh"
                          value={formValues['total_kwh'] as string}
                          setValue={(value) => updateFormValue('total_kwh', value)}
                          error={formErrors['total_kwh']}
                          onSubmit={updateEquipmentUsage}
                          required
                          fill="horizontal"
                        />
                      ) : (
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Total kWh
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {formatNumber(equipmentUsage.total_kwh, true) || '0.00'}
                          </Text>
                        </Box>
                      )}
                      <Box fill="horizontal" gap="xsmall">
                        <Text color="accent-1" size="small" toUpperCase>
                          RECs Required
                        </Text>
                        <Text fontFamily="Lato, sans-serif">
                          {formatNumber(equipmentUsage.recs_required, true) || '0.00'}
                        </Text>
                      </Box>
                      <Box fill="horizontal" gap="xsmall">
                        <Text color="accent-1" size="small" toUpperCase>
                          Credits Generated
                        </Text>
                        <Text fontFamily="Lato, sans-serif">
                          {formatNumber(equipmentUsage.credits_generated, true) || '0.00'}
                        </Text>
                      </Box>
                    </Box>
                  </FormCardSection>
                )}
                {!isMetered && (
                  <FormCardSection title="Usage Details">
                    {canEditQuarter ? (
                      <Box direction="row" gap="1rem">
                        <Input
                          label="Charge Cycles / Shift"
                          value={formValues['charge_cycles_per_shift'] as string}
                          setValue={(value) => updateFormValue('charge_cycles_per_shift', value)}
                          name="charge_cycles_per_shift"
                          error={formErrors['charge_cycles_per_shift']}
                          onSubmit={updateEquipmentUsage}
                          required
                          fill="horizontal"
                        />
                        <Input
                          label="Shifts / Day"
                          value={formValues['shifts_per_day'] as string}
                          setValue={(value) => updateFormValue('shifts_per_day', value)}
                          name="shifts_per_day"
                          error={formErrors['shifts_per_day']}
                          onSubmit={updateEquipmentUsage}
                          required
                          fill="horizontal"
                        />
                        <Input
                          label="Work Days / Quarter"
                          value={formValues['work_days_per_quarter'] as string}
                          setValue={(value) => updateFormValue('work_days_per_quarter', value)}
                          name="work_days_per_quarter"
                          error={formErrors['work_days_per_quarter']}
                          onSubmit={updateEquipmentUsage}
                          required
                          fill="horizontal"
                        />
                      </Box>
                    ) : (
                      <Box direction="row" gap="1rem">
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Charge Cycles / Shift
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {formatNumber(equipmentUsage.charge_cycles_per_shift ?? '', true) || '-'}
                          </Text>
                        </Box>
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Shifts / Day
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {formatNumber(equipmentUsage.shifts_per_day ?? '', true) || '-'}
                          </Text>
                        </Box>
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Work Days / Quarter
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {formatNumber(equipmentUsage.work_days_per_quarter ?? '', true) || '-'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {canEditQuarter ? (
                      <Box direction="row" gap="1rem">
                        <Input
                          label="Charger Efficiency Rating"
                          value={formValues['percent_charger_efficiency_rating'] + (!canEditQuarter ? ' %' : '')}
                          setValue={(value) => updateFormValue('percent_charger_efficiency_rating', value)}
                          name="percent_charger_efficiency_rating"
                          componentType={canEditQuarter ? 'percentage' : undefined}
                          error={formErrors['percent_charger_efficiency_rating']}
                          onSubmit={updateEquipmentUsage}
                          disabled={!canEditQuarter}
                          disabledBackground="white"
                          required
                          fill="horizontal"
                        />
                        <Input
                          label="Charge Return Factor"
                          value={formValues['percent_charge_return_factor'] + (!canEditQuarter ? ' %' : '')}
                          setValue={(value) => updateFormValue('percent_charge_return_factor', value)}
                          name="percent_charge_return_factor"
                          componentType={canEditQuarter ? 'percentage' : undefined}
                          error={formErrors['percent_charge_return_factor']}
                          onSubmit={updateEquipmentUsage}
                          disabled={!canEditQuarter}
                          disabledBackground="white"
                          required
                          fill="horizontal"
                        />
                        <Input
                          label="Depth of Discharge"
                          value={formValues['percent_depth_of_discharge'] + (!canEditQuarter ? ' %' : '')}
                          setValue={(value) => updateFormValue('percent_depth_of_discharge', value)}
                          name="percent_depth_of_discharge"
                          componentType={canEditQuarter ? 'percentage' : undefined}
                          error={formErrors['percent_depth_of_discharge']}
                          onSubmit={updateEquipmentUsage}
                          disabled={!canEditQuarter}
                          disabledBackground="white"
                          required
                          fill="horizontal"
                        />
                      </Box>
                    ) : (
                      <Box direction="row" gap="1rem">
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Charger Efficiency Rating
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {equipmentUsage.percent_charger_efficiency_rating
                              ? `${formatNumber(equipmentUsage.percent_charger_efficiency_rating, true)} %`
                              : '-'}
                          </Text>
                        </Box>
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Charge Return Factor
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {equipmentUsage.percent_charge_return_factor
                              ? `${formatNumber(equipmentUsage.percent_charge_return_factor, true)} %`
                              : '-'}
                          </Text>
                        </Box>
                        <Box fill="horizontal" gap="xsmall">
                          <Text color="accent-1" size="small" toUpperCase>
                            Depth of Discharge
                          </Text>
                          <Text fontFamily="Lato, sans-serif">
                            {equipmentUsage.percent_depth_of_discharge
                              ? `${formatNumber(equipmentUsage.percent_depth_of_discharge, true)} %`
                              : '-'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    <Line margin="0.5rem" />
                    <Box direction="row" gap="1rem">
                      <Box fill="horizontal" gap="xsmall">
                        <Text color="accent-1" size="small" toUpperCase>
                          Total kWh
                        </Text>
                        <Text fontFamily="Lato, sans-serif">
                          {formatNumber(equipmentUsage.total_kwh, true) || '0.00'}
                        </Text>
                      </Box>
                      <Box fill="horizontal" gap="xsmall">
                        <Text color="accent-1" size="small" toUpperCase>
                          kWh / Charge Cycle
                        </Text>
                        <Text fontFamily="Lato, sans-serif">
                          {formatNumber(equipmentUsage.kwh_per_charge_cycle?.toFixed(2) ?? '', true) || '0.00'}
                        </Text>
                      </Box>
                      <Box fill="horizontal" gap="xsmall">
                        <Text color="accent-1" size="small" toUpperCase>
                          Credits Generated
                        </Text>
                        <Text fontFamily="Lato, sans-serif">
                          {formatNumber(equipmentUsage.credits_generated, true) || '0.00'}
                        </Text>
                      </Box>
                    </Box>
                  </FormCardSection>
                )}
              </Box>
            </Box>
          )}
        </FormCard>
      </Form>
    </FormPage>
  );
});

export type EquipmentUsageDetailsProps = object;
