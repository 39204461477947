import '/src/lib/sentry/instrument';

import { Grommet } from 'grommet';
import React from 'react';
import devFavicon from 'jsx:/public/images/dev-favicon.png';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootErrorBoundary } from '/src/components';
import { RootContextProvider } from '/src/context';
import '/src/index.css';
import { AppRoutes } from '/src/routes';
import theme from '/src/theme';
import { config } from './config';

const rootElement = document.getElementById('app');

if (process.env.NODE_ENV === 'development') {
  const devFaviconUrl = new URL(devFavicon, config.client.url).href;
  document.getElementById('favicon')?.setAttribute('href', devFaviconUrl);
}

rootElement && ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <RootContextProvider>
      <BrowserRouter>
        <Grommet theme={theme}>
          <RootErrorBoundary>
            <AppRoutes />
            <ToastContainer
              position="top-right"
              autoClose={1750}
              hideProgressBar={false}
              newestOnTop={false}
              pauseOnFocusLoss={false}
              draggable={false}
              closeOnClick
              pauseOnHover
            />
          </RootErrorBoundary>
        </Grommet>
      </BrowserRouter>
    </RootContextProvider>
  </React.StrictMode>
);
