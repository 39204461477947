import { Button, Card, CardBody, Form, Grid, Keyboard, Main, Spinner, TextInput } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, TLoginRequest } from '../api';
import { config } from '../config';
import { LoginDataForm } from '../lib/types';
import { getPageTitle } from '../utils';
import { Box, FooterNav, FuseLogo, InactiveUserModal, Link, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';

const defaultFormData: LoginDataForm = {
  email: '',
  password: '',
};

const Login = observer(() => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const navigate = useNavigate();

  /** State **/
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showInactiveModal, setShowInactiveModal] = useState(false);

  // Form values
  const [formValues, setFormValues] = useState<LoginDataForm>(defaultFormData);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Methods **/
  const validateForm = () => {
    const errors: Record<string, string> = {};
    if (!formValues.email) errors['email'] = 'Email address is required';
    if (!formValues.password) errors['password'] = 'Password is required';
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      setIsLoggingIn(true);

      const loginData: TLoginRequest = {
        email: formValues.email,
        password: formValues.password,
      };

      await userStore.login(loginData);
      await globalStore.fetchAll();
      toast.success(toastMessages.login.success);
      navigate(globalStore.previousPath || userStore.isInternalUser ? '/clients' : '/', { replace: true });
    } catch (err) {
      const error = err as ApiError;
      if ((error.body as Record<string, string>).message?.toLowerCase() === 'inactive account') {
        setShowInactiveModal(true);
      } else {
        globalStore.handleApiError(error, toastMessages.login.error);
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Login');
  }, []);

  /** Render **/
  return (
    <Grid height="100vh" rows={['flex']} columns={['auto']} areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}>
      <Main gridArea="main" background="light">
        <Box justify="center" flex="grow">
          <Box alignSelf="center" margin={{ bottom: '2rem' }}>
            <FuseLogo width="14rem" height="3.75rem" />
          </Box>
          <Card alignSelf="center" pad="2rem" width="28rem" flex={undefined}>
            <Form>
              <CardBody flex="grow">
                <Box margin={{ bottom: '0.5rem' }}>
                  <Text size="1.5rem" color="dark-2" weight={300}>
                    Sign In
                  </Text>
                </Box>
                <Box>
                  {formErrors['email'] && (
                    <Text color="red" size="small">
                      {formErrors['email']}
                    </Text>
                  )}
                  <Keyboard onEnter={() => handleLogin()}>
                    <TextInput
                      name="email"
                      type="email"
                      placeholder="Email"
                      autoComplete="username"
                      required
                      value={formValues.email}
                      onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                      style={{ borderRadius: '0.375rem', fontFamily: 'Lato, sans-serif' }}
                    />
                  </Keyboard>
                </Box>
                <Box>
                  {formErrors['password'] && (
                    <Text color="red" size="small">
                      {formErrors['password']}
                    </Text>
                  )}
                  <Keyboard onEnter={() => handleLogin()}>
                    <TextInput
                      name="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="password"
                      required
                      value={formValues.password}
                      onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                      style={{ borderRadius: '0.375rem', fontFamily: 'Lato, sans-serif' }}
                    />
                  </Keyboard>
                </Box>
                <Button
                  type="submit"
                  disabled={!formValues.email || !formValues.password}
                  onClick={() => handleLogin()}
                  margin={{ bottom: '1rem' }}
                >
                  <Box background="accent-1" pad={{ horizontal: '1rem', vertical: '0.5rem' }} borderRadius="0.5rem">
                    {!isLoggingIn && (
                      <Text alignSelf="center" size="1.25rem" color="white" lineHeight="1.875rem">
                        SIGN IN
                      </Text>
                    )}
                    {isLoggingIn && <Spinner height="1.875rem" width="1.875rem" alignSelf="center" color="white" />}
                  </Box>
                </Button>
              </CardBody>
            </Form>
          </Card>
          <Box direction="row" justify="center" margin={{ vertical: '1rem' }}>
            <Text lineHeight="1.5rem" fontFamily="Lato, sans-serif">
              Trouble Signing In?{' '}
              <Link to="/reset-password" style={{ textDecoration: 'none' }}>
                Reset Password
              </Link>
            </Text>
          </Box>
          {showInactiveModal && <InactiveUserModal setIsVisible={setShowInactiveModal} />}
        </Box>
        <FooterNav flex="shrink" />
      </Main>
    </Grid>
  );
});

export default Login;
