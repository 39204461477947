import { Form, ResponsiveContext } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ClientService, FacilityService, TCreateFacilityRequest } from '/src/api';
import {
  Box,
  BreadcrumbNav,
  ContactsSelect,
  FirstActivePeriodSelect,
  FormCard,
  FormCardSection,
  FormPage,
  Input,
  Line,
  Select,
  UtilitySelect,
} from '/src/components';
import { config } from '/src/config';
import { useGlobalStore, useUserStore } from '/src/context';
import { Client, ClientReportingPeriod, TClientProgramId, TRegionId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, DateString, FacilityDataForm, FormMetadata, SelectOptions } from '/src/lib/types';
import { getFormErrors, getIsMobile, getPageTitle } from '/src/utils';

export const CreateFacilityPage = observer(() => {
  /** Context **/
  const navigate = useNavigate();
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const screenSize = useContext(ResponsiveContext);
  const params = useParams();

  /** Refs **/
  const defaultFormData = useRef<FacilityDataForm>({
    name: '',
    address_line1: '',
    address_line2: '',
    address_city: '',
    address_region_id: null,
    address_post_code: '',
    utility_id: null,
    program_id: null,
    first_active_reporting_quarter: '',
    last_active_reporting_quarter: null,
  });

  /** State **/
  const [isFetchingPrograms, setIsFetchingPrograms] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [client, setClient] = useState<Client>();
  const [clientProgramOptions, setClientProgramOptions] = useState<SelectOptions<TClientProgramId>>();

  const [formValues, setFormValues] = useState<FacilityDataForm>(defaultFormData.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const clientId = parseInt(params.client_id ?? '');
  const isMobile = getIsMobile(screenSize);
  const isCanada = client?.hq_address_region?.country_id === CountryId.Canada;
  const programEmptyMessage = !formValues.address_region_id
    ? `Choose a ${isCanada ? 'province' : 'state'} and first reporting period to see Programs.`
    : `No Programs are registered for this ${isCanada ? 'province' : 'state'}.`;

  const regionOptions = useMemo(() => {
    if (client?.hq_address_region && globalStore.regions) {
      if (!isCanada) {
        return globalStore.regions.filter((r) => !isCanada && r.short_code && r.country_id === 1)
          .map((region) => ({
            label: `${region.name} (${region.short_code})`,
            value: region.id,
          }));
      }
      return globalStore.regions.filter((r) => r.country_id === client?.hq_address_region?.country_id);
    }
    return [];
  }, [client?.hq_address_region, globalStore.regions, isCanada]);

  const programName = useMemo(
    () => (formValues.program_id ? clientProgramOptions?.find((o) => o.value === formValues.program_id)?.label : ''),
    [formValues.program_id, clientProgramOptions]
  );

  const isLoading = !client;

  /** Methods **/
  const updateFormValue = (key: string, value: ClientReportingPeriod | string | number | DateString | null) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  /**
   * Fetches page data.
   */
  const fetchPageData = async () => {
    try {
      const client =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === clientId)
          : await ClientService.get({ id: clientId });

      if (client) {
        setClient(client);
      }
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchClient.error);
    }
  };

  /**
   * Creates a Facility with the current form values.
   * @returns
   */
  const createFacility = async () => {
    if (!client?.name || isCreating || isFetchingPrograms) return;

    const formFields: FormMetadata = {
      name: { label: 'Name', max: 50 },
      address_line1: { label: 'Address', max: 100, required: true },
      address_line2: { label: 'Address', max: 100 },
      address_city: { label: 'City', max: 50, required: true },
      address_region_id: { label: 'Region', required: true },
      address_post_code: { label: 'Postal code', max: 20, required: true },
      program_id: { label: 'Program', required: true },
      utility_id: { label: 'Utility', required: !isCanada },
      first_active_reporting_quarter: { label: 'First Reporting Period', required: true },
    };

    const errors = getFormErrors(formFields, formValues);
    if (errors) {
      setFormErrors(errors);
      return;
    }

    const newFacility: TCreateFacilityRequest = {
      client_id: clientId,
      name: formValues.name || null,
      address_line1: formValues.address_line1,
      address_line2: formValues.address_line2 || null,
      address_city: formValues.address_city,
      address_region_id: formValues.address_region_id ?? 0,
      address_post_code: formValues.address_post_code,
      program_id: formValues.program_id,
      utility_id: isCanada ? null : formValues.utility_id,
      first_active_reporting_quarter: formValues.first_active_reporting_quarter ?? '',
    };

    try {
      setIsCreating(true);
      const facility = await FacilityService.create(newFacility);
      toast.success(toastMessages.createFacility.success);
      navigate(`/clients/${clientId}/facilities/${facility.id}`);
    } catch (err) {
      const error = err as ApiError;
      if (error.body.message?.toLowerCase() === 'facility already exists') {
        globalStore.handleApiError(error, () =>
          toastMessages.duplicateFacility.error({
            ...error,
            body: { ...error.body, program_name: programName },
          })
        );
      } else {
        globalStore.handleApiError(error, toastMessages.createFacility.error);
      }
    } finally {
      setIsCreating(false);
    }
  };

  /**
   * Fetches a filtered list of available Programs.
   * @param client
   * @param regionId
   * @param startPeriod
   */
  const fetchPrograms = async (client: Client, regionId: TRegionId, startPeriod: DateString) => {
    try {
      setIsFetchingPrograms(true);
      updateFormValue('program_id', null);
      const options = (
        await ClientService.listClientPrograms({
          id: client.id,
          reporting_period_type_id: client.reporting_period_type_id,
          start_reporting_quarter: startPeriod,
          end_reporting_quarter: startPeriod,
          program_region_id: regionId,
        })
      ).map((p) => ({
        label: p.program.name,
        value: p.program_id,
      }));
      setClientProgramOptions(options);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listPrograms.error);
    } finally {
      setIsFetchingPrograms(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Add Facility');
  }, []);

  useEffect(() => {
    if (isLoading) {
      fetchPageData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (client && formValues.first_active_reporting_quarter && formValues.address_region_id) {
      fetchPrograms(client, formValues.address_region_id, formValues.first_active_reporting_quarter);
    }
  }, [client, formValues.first_active_reporting_quarter, formValues.address_region_id]);

  /** Render **/
  return (
    <FormPage
      title="Add Facility"
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
            { name: client?.name ?? '', link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
            { name: 'Facilities', link: `/clients/${clientId}/facilities` },
          ]}
          currentPageName={'Add Facility'}
        />
      }
      isLoading={isLoading}
    >
      <FormCard
        title="General Information"
        icon={<CircleInformation size="24px" color="brand" />}
        isLoading={isLoading || isCreating}
        onSubmit={createFacility}
      >
        <Form>
          <Box direction={isMobile ? 'column' : 'row'} margin={{ bottom: '1rem' }}>
            <Box width="60%" gap="1rem">
              <FormCardSection title="Facility Information">
                <Box row gap="1rem">
                  <Input
                    id="facility_name"
                    label="Name"
                    fill="horizontal"
                    value={formValues.name ?? ''}
                    setValue={(value) => updateFormValue('name', value)}
                    error={formErrors['name']}
                    onSubmit={createFacility}
                  />
                  <Box width="50%">
                    <FirstActivePeriodSelect
                      id="first_active_reporting_quarter"
                      label="First Reporting Period"
                      placeholder="Select Period..."
                      value={formValues.first_active_reporting_quarter ?? ''}
                      setValue={(value) => updateFormValue('first_active_reporting_quarter', value)}
                      clientId={client?.id}
                      reportingPeriodTypeId={client?.reporting_period_type_id}
                      firstActivePeriod={client?.first_active_reporting_quarter}
                      error={formErrors['first_active_reporting_quarter']}
                      required
                    />
                  </Box>
                </Box>
                <Input
                  id="address_line1"
                  autoComplete="address_line1"
                  label="Address"
                  value={formValues.address_line1}
                  setValue={(value) => updateFormValue('address_line1', value)}
                  error={formErrors['address_line1']}
                  onSubmit={createFacility}
                  required
                />
                <Input
                  id="address_line2"
                  autoComplete="address_line2"
                  label="Address (continued)"
                  value={formValues.address_line2 ?? ''}
                  setValue={(value) => updateFormValue('address_line2', value)}
                  error={formErrors['address_line2']}
                  onSubmit={createFacility}
                />
                <Box row gap="1rem">
                  <Input
                    id="address_city"
                    label="City"
                    value={formValues.address_city}
                    setValue={(value) => updateFormValue('address_city', value)}
                    error={formErrors['address_city']}
                    onSubmit={createFacility}
                    width="50%"
                    required
                  />
                  <Select
                    id="address_region_id"
                    label="State / Province"
                    value={formValues.address_region_id}
                    setValue={(value) => updateFormValue('address_region_id', value)}
                    options={regionOptions}
                    error={formErrors['address_region_id']}
                    required
                  />
                  <Input
                    id="address_post_code"
                    label="Postal Code"
                    value={formValues.address_post_code}
                    setValue={(value) => updateFormValue('address_post_code', value)}
                    error={formErrors['address_post_code']}
                    onSubmit={createFacility}
                    required
                  />
                </Box>
              </FormCardSection>
              <Line margin="0.5rem" />
              <Box row gap="1rem">
                <Select
                  id="program"
                  name="program"
                  label="Program"
                  placeholder="Choose..."
                  emptySearchMessage={programEmptyMessage}
                  value={formValues.program_id}
                  setValue={(value) => updateFormValue('program_id', value)}
                  error={formErrors['program_id']}
                  options={clientProgramOptions ?? []}
                  required
                  fill="horizontal"
                />
                {!isCanada && (
                  <UtilitySelect
                    id="utility"
                    label="FSE Utility"
                    placeholder="Choose..."
                    emptySearchMessage={
                      !formValues.address_region_id
                        ? 'Choose a state to see available Utilities.'
                        : 'No Utilities found.'
                    }
                    value={formValues.utility_id}
                    setValue={(value) => updateFormValue('utility_id', value)}
                    error={formErrors['utility_id']}
                    regionId={formValues.address_region_id}
                    required={!isCanada}
                    fill="horizontal"
                  />
                )}
              </Box>
            </Box>
            <Line direction="vertical" margin="3rem" />
            <Box width="40%">
              <ContactsSelect isFacility />
            </Box>
          </Box>
        </Form>
      </FormCard>
    </FormPage>
  );
});
