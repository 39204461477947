import { Button, Card, CardBody } from 'grommet';
import { Share } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { TClientId } from '/src/lib/models';
import { tooltipMessages } from '/src/lib/tooltips';
import { pxToRem } from '/src/utils';

export const DashboardHeader: React.FC<DashboardHeaderProps> = (props) => {
  /** Props **/
  const { clientName, clientId, onExportPDF, ...boxProps } = props;

  /** Stores **/
  const navigate = useNavigate();
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** Render **/
  return (
    <Card
      height={!userStore.isFacilityUser ? pxToRem(80) : undefined}
      animation={!globalStore.didAnimateDashboard ? { type: 'slideDown', duration: 300, size: 'large' } : undefined}
      onAnimationEnd={() => globalStore.setDidAnimateDashboard(true)}
      {...boxProps}
    >
    <CardBody pad={{ horizontal: '1.25rem', vertical: '1.25rem' }} direction="row" align="center" justify="between">
      <Box direction="row" align="center">
        {!userStore.isFacilityUser && (
          <>
            <Text size="1.75rem" weight={400}>
              {clientName}
            </Text>
            <Button
              icon={<Share size="16px" />}
              onClick={() => navigate(`/clients/${clientId}`)}
              tip={tooltipMessages.companyPageLink}
            />
          </>
        )}
        {userStore.isFacilityUser && (
          <Box justify="center" gap="xxsmall">
            <Text size="1.75rem" weight={400}>
              Your Facilities
            </Text>
            <Text size="1.1rem" fontFamily="Lato, sans-serif" weight={300}>
              {clientName}
            </Text>
          </Box>
        )}
        </Box>
        <Button 
          id="export-pdf-button"
          label="Export to PDF" 
          style={{ fontWeight: 400 }} 
          onClick={onExportPDF} 
        />
      </CardBody>
    </Card>
  );
};

export type DashboardHeaderProps = BoxProps & {
  clientName?: string;
  clientId?: TClientId;
  onExportPDF?: () => void;
};